/* Modules */
import { useEffect } from "react"

/* Components */
import Header from '../../composants/Header'
import BoutonScrollToTop from "../../composants/BoutonScrollToTop"
import Footer from '../../composants/Footer'

/* Code */
export default function CGV() {
    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = "Conditions Générales de Vente | Maison du Revêtement";
        document.getElementsByTagName("meta")[2].content=`Consulter les conditions générales de vente de Maison du Revêtement.`;
    }, []);

    return (
        <>
            <header className="header header_cgv">
                <Header />
            </header>
            <main className="cgv">
                <h1>Conditions Générales de Vente et d'Utilisation</h1>
                <span className="cgv_ligne"></span>
                <p className="p_un">Les présentes conditions de vente sont conclues d'une part par la Société Maison du
                Revêtement, SAS au capital de 210 600 € ayant son siège à VILLENEUVE-LA-GARENNE
                (92390), 23 Avenue du Chemin des Reniers, immatriculée au R.C.S de NANTERRE sous le
                n° 917 977 035, ci-après dénommée, « le Vendeur » ou « la Société » et d'autre part,
                par tout consommateur, non professionnel ou professionnel, dénommé ci-après « le
                Client ». Les conditions générales de ventes définissent les droits et obligations de la
                société et de son client dans le cadre de la vente des marchandises et sont régies par le
                droit des contrats issu du Code civil et du Code de la Consommation et au principe de
                l'exécution de bonne foi des contrats. Les conditions générales de vente sont remises
                au client, lors de chaque devis, bon de commande et bon de livraison. Le seul fait de passer une commande ou d'accepter une
                offre écrite du vendeur emporte l’acceptation sans réserve des présentes conditions
                générales de vente par le client. Les caractéristiques essentielles de chaque marchandise
                sont indiquées dans le catalogue ou sur le site du vendeur, accompagnée, le cas échéant
                d'une photographie de manière à respecter les dispositions de l’article L 111 -1, du code
                de consommation. Toute photographie est fournie à titre indicatif et n'a par conséquent
                aucun caractère contractuel et ne peut assurer une similitude parfaite avec la
                marchandise commandée, notamment en ce qui concerne les couleurs, tonalités,
                calibres, aspect de surface, du fait de procédés de fabrication, de matériaux naturels ou
                artisanaux et dès lors ne sauraient engager le vendeur. Il en est de même pour tout
                échantillons exposés en magasin lesquelles sont présentés à titre indicatif et ne
                disposent pas valeur contractuelle. Les offres sont valables dans la limite des stocks
                disponibles. Les présentes conditions priment sur tous les autres documents qui
                peuvent être échangés entre le vendeur et le client, sauf accord dérogatoire et à
                l'exception du document d'ouverture de compte par un professionnel qui reprend les
                présentes conditions générales de vente et les dispositions particulières spécifiques aux
                professionnels lesquelles prévalent sur les présentes.
                </p>
                {/* <div className="conditionsOffre">
                    <h2>* OFFRE PARQUET</h2>
                    <p>Jusqu'au 31/05/2024 inclus, bénéficiez jusqu'à 20% de remise immédiate sur une sélection de parquet.</p>
                </div> */}
                <div>
                    <h2>COMMANDES</h2>
                    <p>Toute commande implique de la part du client l'acceptation sans réserve des présentes
                    conditions générales de vente. Toute commande prise est ferme et définitive.
                    Toute modification doit être formulée dans les deux (2) jours, à compter de la signature
                    du bon de commande sous peine d'irrecevabilité, sauf accord écrit du vendeur. Les
                    offres sont valables dans la limite des stocks disponibles. En cas de devis, l'offre restera
                    valable UN (1) MOIS sauf stipulations contraires portées sur celle-ci. En général, les
                    produits sont livrés avec une « notice » fournis par le fabricant présentant leurs
                    caractéristiques et le cas échéant, les recommandations relatives aux conditions de
                    pose et/ou d'installation et d'entretien desdits produits. De même, le client s'engage
                    expressément à ce que toute installation et/ou pose de produits soit effectuée par un
                    professionnel dans les règles de l'art, en respectant les caractéristiques et
                    recommandations desdits produit, sauf à engager sa seule et exclusive responsabilité.
                    Le client s'engage à respecter scrupuleusement les recommandations, ci-dessus
                    exposées, sous peine de la perte de garantie, ci-après exposée. Le client a la possibilité
                    de contacter le vendeur pour obtenir les renseignements complémentaires sur
                    l’ensemble de ses produits. En aucun cas, le vendeur ne sera tenu responsable des
                    erreurs commises du fait des informations fournies par le client pour la prise de
                    commande telle que plans, gabarits, etc.
                    En cas de commandes complémentaires, la Société ne peut garantir la similitude du
                    produit avec celui précédemment livré.
                    Le vendeur est à tout moment en droit de réactualiser ou de retirer de la vente les
                    produits. Le vendeur se réserve le droit d'annuler ou de refuser toute commande d'un
                    client avec lequel il existerait un litige relatif paiement ou à une contestation d'une
                    commande antérieure ou en cours.<br />
                    <strong>Aire géographique :</strong> La société vend ses produits en France Métropolitaine, dans les
                    départements d’Outre-mer et les collectivités ainsi qu'à l’étranger. Il appartient au client
                    de s'informer auprès de l'autorité locale de son pays des éventuelles limitations
                    d'importation et des taxes douanières portant sur produits qu'il envisage de
                    commander. Si la livraison est effectuée par transporteur privé et indépendant du
                    vendeur, s'appliquent pour celle-ci les dispositions contractuelles du transporteur.
                    </p>
                </div>
                <div>
                    <h2>DÉLAIS</h2>
                    <p>Le délai de livraison court à partir du lendemain de la date de commande dans le cas d'un règlement par carte bancaire, sous réserve
                    d'acceptation de celui-ci. Dans le cas d'une commande de produits ayant des délais de livraison
                    différents, la totalité de la commande sera livrée sur la base du délai le plus long. En
                    principe, pour les produits non stockés dans nos entrepôts, le délai de disponibilité du
                    produit ne saurait dépasser TROIS (3) MOIS sauf pour le cas de commande portant sur
                    des fabrications spécifiques et sur mesure (dimensions, couleurs, etc.), la fixation du
                    délai de disponibilité s'effectue en fonction de faisabilité des demandes du client. Pour
                    les produits stockés dans nos entrepôts, le retrait en magasin ou départ livraison se fait
                    sous 24 heures à 3 mois maximum. L'indication du délai de livraison porté sur le
                    bon de commande engage le vendeur qui se réserve le droit de le prolonger en cas de
                    force majeure ou fortuits ou des cas ci-après stipulés. En ce qui concerne le client, 
                    le délai de livraison indiqué lors de l'enregistrement de la commande
                    n'est donné qu'à titre indicatif et n'est aucunement garanti.
                    Les risques dont ceux du transport sont à la charge du client à compter du moment où
                    les produits ont quitté les locaux du vendeur. Le client dégage le vendeur de tout
                    engagement relatif au délai de livraison et ne saurait prétendre au paiement d'une
                    quelconque indemnité dans les cas suivants : <br />
                    - les modifications de la commande acceptées par le vendeur sont fournies tardivement
                    et/ou incomplètes ;<br />
                    - l'accès au lieu de livraison n'a pas été possible à la date prévue du fait du client ;<br />
                    - les conditions de paiement ne sont pas respectées par le client.
                    </p>
                </div>
                <div>
                    <h2>LIVRAISON OU ENLÈVEMENT SUR PLACE </h2>
                    <p>Les livraisons sont faites à l’adresse indiquée sur le bon de commande. La livraison ou
                    l’enlèvement s'effectuent de 9h00 à 17h00. Il n'y a pas de livraison le samedi, le dimanche et les jours
                    fériés et/ou chômés. Les marchandises sont remises contre signature et après
                    vérification. Le tarif de la prestation de transport est fixé lors de la passation de la
                    commande en fonction de la quantité, du poids, de la destination et de l'accès au lieu
                    de livraison. Le vendeur ne saurait être tenu pour responsable des dommages qui
                    résulteraient d'une difficulté d’accès lors de la livraison même si cette difficulté a été
                    signalée lors de la commande. Le vendeur ne saurait être tenu pour responsable des
                    retards résultant notamment d'un des évènements suivants : un cas de force majeure
                    ou d'événements tels que confinement, grève, arrêt de travail total ou partiel dans l'usine
                    du vendeur ou chez ses fournisseurs, épidémie, guerre, réquisition, incendie,
                    inondation, interruption ou retard dans les transports, mesures légales ou
                    administratives empêchant, restreignant, retardant ou interdisant fabrication ou
                    l'importation de la marchandise. En cas de commande supplémentaire dite
                    contremarque, la facturation s'effectuera automatiquement au jour de la réception des
                    marchandises par le vendeur. Le client ne peut prétendre à des dommages-intérêts ou
                    demander l'annulation de la commande dans l'hypothèse où la livraison interviendrait
                    avec un retard raisonnable.
                    Livraison est effectuée par la remise directe de la marchandise au client :<br />
                    1) L'enlèvement des marchandises par le client s'effectue dans les locaux du vendeur,
                    sur la chaussée. En cas de non retrait des matériaux commandés, des frais de stockage
                    et de manutention sont dus au vendeur, à raison d’une somme forfaitaire de DIX EUROS
                    (10 €) par jour de retard commençant à courir à partir de la date de retrait indiquée sur bon de commande, sans autre formalité.<br />TOUTE COMMANDE NON ENLEVÉE DANS LE
                    DELAI DE TROIS MOIS, A COMPTER DE LA DATE DE LIVRAISON INDIQUEE SUR LE BON
                    DE COMMANDE, ET APRES UNE SIMPLE MISE EN DEMEURE D'ENLEVER ET PAYER DANS
                    LES QUARANTE HUIT HEURES LES MARCHANDISES, ENTRAINERA L'ACQUISITION
                    DEFINITIVE DE L’ACOMPTE VERSE OUTRE L'ABANDON DES MARCHANDISES AU PROFIT
                    DU VENDEUR A TITRE DE DÉDIT PAR LE VENDEUR EN REPARATITION DES PREJUDICES
                    SUBIS COMPRENANT NOTAMMENT LES FARIS SUS ENONCES.<br />
                    2) Livraison est effectuée au lieu indiqué par le client sur le bon de commande.
                    Afin de mieux effectuer les livraisons, le client doit indiquer, le cas échéant, les
                    conditions d'accès à l'adresse de livraison lorsque celles-ci sont difficiles et/ou
                    spécifiques telles que : cols de montagne, îles, bases militaires, petites rues, impasses,
                    rues piétonnes, rue en travaux, rue interdites ou inaccessibles aux véhicules de plus de
                    3T5, etc. pouvant amener le vendeur à revoir la faisabilité du transport ou un coût
                    supplémentaire. Dans le cas où la livraison n'aurait pu avoir lieu au moment convenu
                    en raison de difficultés d'accès à l’adresse du client non signalées lors de la commande,
                    le, vendeur se réserve le droit de livrer le client selon le jour de son choix et de compter
                    des frais supplémentaires à régler, à réception des marchandises directement au
                    transporteur.
                    La livraison s'effectue par défaut devant la boite aux lettres du client, au pied de l'immeuble, sur la
                    chaussée.
                    </p>
                </div>
                <div>
                    <h2>RÉCEPTION DES MARCHANDISES</h2>
                    <p>Le vendeur confie le transport à un réseau national de messagerie ou par son propre
                    service de livraison, selon le cas. Les marchandises sont remises au client contre
                    émargement du bon de livraison. Le client s'engage à en contrôler le contenu et ta
                    conformité au bon de livraison remis au transporteur ou au livreur du vendeur. Si les
                    marchandises sont conformes avec la commande passée, le client s'engage à signer le
                    bon de livraison remis. Cette signature signifie que le client reconnait avoir pris
                    possession de sa commande et qu'il est satisfait. En cas de défaut de signature ou de
                    paiement du solde du prix, la marchandise ne sera pas remise ou délivrée. La mention
                    lors de la réception de marchandise d'une formule visant à permettre au client de
                    contester sa commande uniquement une fois celle-ci déballée telle que notamment
                    « sous réserve de déballage », « sous réserve de fonctionnement » etc. n'a aucune
                    valeur. La mention « sous réserve de déballage » ne constitue pas une réserve
                    caractérisée et n’a AUCUNE VALEUR JURIDIQUE. Il est alors indispensable que vous
                    décriviez précisément l'état de l'emballage et/ou de votre produit (Casse, Colis ouvert,
                    emballage endommagé, déchiré… sur le coin en bas à gauche, produit rayé...). A noter
                    que la vérification de l'état de votre produit est considérée comme effectuée dès lors
                    que le bon de livraison a été signé. Par conséquent, EN CAS NON RESPECT DES
                    INDICATIONS CI-DESSUS, AUCUNE RECLAMATION NE SERA RECEVABLE (art.L.133-3 du
                    Code du Commerce). Les frais inhérents à une nouvelle livraison seront pris en charge
                    exclusivement et totalement par le client. Si le client constate une avarie de transport,
                    dégâts, commande non conforme, il peut refuser la totalité ou accepter une livraison
                    partielle de sa commande. (Hors cartons abîmés dus au stockage soumis aux
                    intempéries ou ouvert dans une palette filmée) Si le client accepte une livraison
                    partielle, il est impératif de noter sur le récépissé « livraison partielle cause avarie
                    transport, etc. » et de marquer en présence du transporteur ou livreur, les remarques
                    explicites et réserves caractérisées du ou des problèmes constatés, ainsi que de tenir à
                    disposition le ou les colis endommagés, s'ils ne sont pas repris. En l'absence de ces
                    réserves, et de l'information au vendeur dans un délai de 48 heures à compter de la
                    réception des marchandises, par courrier recommandée avec A.R. en indiquant la
                    référence de la commande, aucune réclamation ne saurait être prise en compte par le
                    vendeur et le client ne pourra obtenir aucun remplacement ou remboursement.
                    Aucune réclamation n'est prise en compte en l’absence de réserves explicites sur le bon
                    de livraison. Si le client souhaite formuler une réclamation, il s'engage à l’envoyer dans
                    un délai de 48 heures à compter de la réception des marchandises, par courrier
                    recommandée avec A.R, en indiquant la référence de la commande. Dans le cas,
                    d'impossibilité livrer le client de son fait : tout manquement notamment à un rendez-vous de livraison, entraîne pour celui-ci la perte de la participation aux frais d'envoi et
                    des frais supplémentaires seront à sa charge et réglés directement entre les mains du
                    transporteur. Bien que nous essayons de vous donner délais plus justes possibles sur la
                    commande, en cas de retard fondé ou pas, le client ne pourra prétendre à aucune
                    indemnité et/ou réparation financière pour quelque préjudice que ce soit.
                    </p>
                </div>
                <div>
                    <h2>PRIX</h2>
                    <p>Les prix figurant dans notre catalogue sont des prix T.T.C en EUROS, au départ de nos magasins. Ils
                    comprennent les frais de traitement et d'emballage liés à la commande. Les frais de
                    livraison restent à la charge client et sont déterminés lors de la commande en fonction
                    de la quantité, du poids, de la destination et de l’accès au lieu de livraison. Tout
                    changement du taux de la taxe sur valeur ajoutée pourra être répercuté sur le prix des
                    produits. Le vendeur se réserve de modifier ses prix à tout moment, étant toutefois
                    entendu que le prix figurant au catalogue le jour de la commande sera le seul applicable
                    au client. Tous les produits sont payables à la commande. En aucun cas, le vendeur ne
                    sera tenu de livrer le client si la commande n'est pas réglée dans sa totalité. Les modes
                    de paiements acceptés sont : cartes bancaires privées (exclusivement cartes bleues), Visa, Mastercard ou virements bancaires.
                    Le paiement en espèces ou par chèque est valable uniquement en magasin, tout règlement versé au vendeur est considéré comme un acompte.
                    Le défaut de paiement d’une échéance rend immédiatement exigible toutes les autres créances échues ou à échoir, et ce, de plein
                    droit, sans mise en demeure préalable. Il entraîne automatiquement la résolution des
                    marchés et commandes en cours et la perte de l'acompte au bénéfice du vendeur, à
                    titre d'indemnité forfaitaire ainsi que la reprise des marchandises non payées. La
                    facture acquittée ainsi que les garanties éventuelles seront effectives qu'en échange du
                    solde prix. Le vendeur se réserve le droit d’annuler ou de refuser toute commande d’un
                    client avec lequel il existerait un litige relatif au paiement ou à une contestation d’une
                    commande antérieure ou en cours. En cas de non-respect de la date paiement ou de
                    l'une de ces échéances, des pénalités de retard sont dues au vendeur. Le taux de ces
                    pénalités de retard est de 4 fois le taux de l'intérêt légal. Si le client est en dehors de I
                    ‘Union Européenne ou dans les DOM TOM, la facture sera établie sans TVA. Toute
                    commande livrée hors de France métropolitaine pourra être soumise aux éventuelles
                    taxes locales, droits de douanes, octroi de mer et frais de dédouanement de la livraison.
                    Leur acquittement est à la charge du client et relève de sa seule responsabilité.
                    </p>
                </div>
                <div>
                    <h2>MODALITÉS DE PAIEMENT</h2>
                    <p>Les délais de paiement sont de rigueur. En cas de défaut paiement partiel des
                    marchandises livrées au jour de la réception, le client doit verser au vendeur une
                    pénalité de retard égale à trois fois le taux de l'intérêt légal. Le taux de l’intérêt légal
                    retenu est celui en vigueur au jour de la livraison des marchandises. Cette pénalité est
                    calculée sur le montant hors taxes de la somme restant due, et court à compter de la
                    date d'échéance du prix sans qu'aucune mise en demeure préalable ne soit nécessaire.
                    La facture acquittée ainsi que les garanties éventuelles ne sont effectives qu'en échange
                    du solde du prix.
                    </p>
                </div>
                <div>
                    <h2>GARANTIE</h2>
                    <p>S'applique, en tout état de cause, la garantie légale qui oblige le vendeur professionnel
                    à garantir contre les conséquences des défauts ou vices cachés de la chose vendue
                    conformément à l'article R. 211-4 du Code de la Consommation et à l’article 1641 du
                    Code Civil. La présentation de la facture d'achat acquittée sera exigée lorsque la
                    garantie sera invoquée. La garantie courre dès la date de commande reportée sur cette
                    facture. Les matières premièresrentrant dans la composition de nos produits, bien que
                    soumis à d'important contrôles, ne peuvent échapper à des variations de couleur.
                    Celles-ci bénéficient des tolérances d’usage et la responsabilité du vendeur ne pourrait
                    être mise en cause en cas de différence de teinte en particulier pour la pose en tapis
                    unicolore. Limite et exclusion de la Garantie. Le client est expressément informé que le
                    vendeur n'est pas le fabricant des produits vendus, au sens de la loi n°98-389 du 19 mai
                    1998 et relative à la responsabilité du fait des produits défectueux. En conséquence, en
                    cas de dommages causés à une personne ou à un bien par un défaut du produit, seule
                    la responsabilité du fabricant devra être recherchée par le client, sur la base des
                    informations figurant sur l'emballage du dit produit. La garantie du vendeur se borne
                    au remplacement du produit reconnu défectueux pour vices apparents ou cachées et
                    constatées par nos services, dans la limite des stocks équivalent à l'exclusion de tous
                    coûts et frais annexes : pose, dépose, immobilisations de tous dommages et intérêts et
                    toutes responsabilités, indemnités à quel titre que ce soit. Le client s'engage à prendre,
                    à ses frais exclusifs, une assurance spécifique le couvrant de tous préjudices de quelque
                    nature que ce soit résultant des matériaux achetés, le vendeur n'étant tenu qu’au
                    remplacement desdits produits comme convenu par la garantie. La présente garantie
                    vaut sous réserve d'une utilisation conforme et normale des marchandises et le respect
                    des recommandations du fabricant. Le vendeur s'engage à remplacer les marchandises
                    si les réclamations du client exprimées par lettre avec A.R. se révèlent fondées. En tout
                    état de cause, la responsabilité du vendeur ne saurait excéder celle de ses fabricants et son quantum sera limité au montant de la commande, à l'exclusion de toute autre
                    indemnisation de quelque nature que ce soit et notamment de l’indemnisation de tout
                    préjudice immatériel qui serait la conséquence directe ou indirecte, notamment du
                    défaut des marchandises. Exclusion de la garantie dans les cas suivants : <br />
                    - usure ou vieillissement normal du produit et/ou de non-respect des règles d'entretien
                    spécifiques à chaque matériau notamment et éventuellement celles rapportées dans la
                    notice du fabricant et/ou du vendeur,<br />
                    - dégradations ou d’accident ayant leur origine dans une utilisation incorrecte,<br />
                    - stockage et transport préjudiciable à leur bonne conservation,<br />
                    - des défauts et conséquences dus à un montage non effectué dans les règles de l'art
                    en vigueur par un professionnel ou non,<br />
                    - de la mise en œuvre en dépit de défaut d’usinage, de finitions constatées,<br />
                    - de défauts et conséquences liés à une utilisation non-conforme à l'usage pour lequel
                    le produit est destiné, taux ambiants d'humidité, sols, murs en non-conformités,<br />
                    - de défauts et conséquences liés à toutes causes extérieurs, usage non adapté, usure
                    anormale (chocs, rayures, etc.)<br />
                    - de pose et/ou d’installation par un non professionnel.<br />
                    - de non-respect des recommandations du fabricant et/ou du vendeur.
                    Teintes et aspect : la garantie du vendeur ne pourra pas davantage être mise en cause
                    dans le cas de changement d'aspect provenant de l’altération normale et uniforme des
                    teintes. Il en sera de même en cas de salissures.<br />
                    -Est également exclu de la garantie, le remplacement des produits suite à une
                    commande erronée et validée par le client.<br />
                    -Est également exclu de la garantie, les défauts de conformité, résultant de l'emballage,
                    des instructions de pose et/ou montage et/ou d'installation.
                    </p>
                </div>
                <div>
                    <h2>RESILIATION-CAS DE FORCE MAJEURE</h2>
                    <p>Vous disposez d'un délai de 14 jours pour changer d'avis sur votre achat à distance et ainsi obtenir un remboursement, sauf cas de force majeure.
                    Avant l'expiration du délai de rétractation, vous devez envoyer au vendeur un écrit exprimant votre volonté de vous rétracter.    
                    De convention expresse, un cas de force majeure ou d'évènements tels que confinement, grève, arrêt de                    
                    travail total ou partiel dans l'usine du vendeur ou chez ses fournisseurs, épidémie,
                    guerre, réquisition, incendie, inondation, interruption ou retard dans les transports,
                    mesures légales ou administratives empêchant, restreignant, retardant ou interdisant
                    la fabrication ou l'importation de la marchandise, le vendeur est dégagé de toutes
                    responsabilités à la livraison. Le vendeur tiendra le client au courant en temps opportun,
                    des cas et des évènements ci-dessus énumérés. Dans tous les cas, la livraison dans les
                    délais ne peut intervenir que si le client est à jour de ses obligations envers le vendeur
                    quelle qu'en soit la cause. Toutefois, si une annulation nous parvenait alors que
                    l'expédition ou la livraison est déjà effectuée, l'annulation ne sera pas prise en compte,
                    la date d'enlèvement par le transporteur faisant foi.
                    </p>
                </div>
                <div>
                    <h2>RÉSERVE DE PROPRIETE</h2>
                    <p>Les produits livrés et facturés au client resteront la propriété du vendeur jusqu'au
                    paiement intégral de leurs prix. Le défaut de paiement pourra entraîner la revendication
                    des produits par le vendeur, la restitution étant immédiate et les produits livrés à vos
                    frais, risques et périls. Durant la période s'écoulant de la livraison au transfert de
                    propriété, les risques de perte, vol ou destruction ainsi que les dommages occasionnés
                    par le client restent à sa charge. Le client s'engage à participer activement à
                    l'établissement d’un inventaire des marchandises se trouvant dans ses stocks et dont le
                    vendeur revendique la propriété. A défaut, le vendeur a la faculté de faire constater
                    l'inventaire par huissier de justice aux frais du client. Le vendeur pourra interdire au
                    client de procéder à la revente, la transformation ou l'incorporation des marchandises en
                    cas de retard de paiement. Pour garantir les paiements non encore effectués et
                    notamment le solde du compte client dans les écritures du vendeur, il est expressément
                    stipulé que les droits relatifs aux marchandises livrées même impayées se reporteront
                    sur les produits identiques en provenance du vendeur en stock chez le client, sans qu’il
                    soit besoin d'imputer les paiements sur une vente ou livraison déterminée.
                    </p>
                </div>
                <div>
                    <h2>OBSERVATIONS - EXCEPTIONS</h2>
                    <p>Concernant la conformité des produits fabriqués sur mesure et pour des cas ne se
                    répétant pas aux échantillons proposés, les produits décrits sur le bon de commande,
                    seront établis sous l'entière responsabilité du client.
                    Sauf convention spéciale et écrite, toute commande signée par le client implique son
                    adhésion de plein droit aux présentes conditions générales de vente qu’il reconnaît
                    avoir lues avant signature.
                    Si l’une des clauses ou dispositions des présentes Conditions Générales de Vente venait
                    à être annulée ou déclarée illégale par une décision de justice définitive, cette nullité
                    ou illégalité n’affectera en rien les autres clauses et dispositions, qui continueront à
                    s'appliquer.
                    </p>
                </div>
                <div>
                    <h2>CLAUSE RÉSOLUTOIRE</h2>
                    <p>La résolution du contrat intervient : <br />
                    - en cas de défaut de paiement d’une échéance ; ce défaut de paiement rend
                    immédiatement exigible toutes les autres créances échues ou à échoir, et ce de plein
                    droit, sans mise en demeure préalable et entraine automatiquement la résolution des
                    marchés et commandes en cours, ainsi que la reprise des marchandises non payées.<br />
                    - en cas de non-enlèvement des marchandises dans un délai de trois mois dans
                    l’hypothèse où le client n'aurait pas récupéré sa commande au magasin ou n'aurait pas
                    permis la bonne réalisation de la livraison dans les délais indiqués sur le bon de
                    commande.
                    Le vendeur est en droit de conserver la somme versée au moment de la passation de la
                    commande en raison du préjudice causé par comportement du client.
                    Le vendeur est en outre en droit, en cas de résolution du contrat, de demander des
                    dommages-intérêts.
                    </p>
                </div>
                <div>
                    <h2>DROIT APPLICABLE ET COMPÉTENCE</h2>
                    <p>Le Tribunal de commerce de Paris est seul compétent pour trancher litiges issus des
                    présentes conditions générales de vente, même en cas d'appel en garantie de la
                    pluralité de défenseurs et nonobstant toute clause contraire. Le client qui commande
                    en nos magasins, exclusivement en langue française, est réputé acheter en France et
                    soumis à la loi française. L’application de la Convention de Vienne sur la vente
                    internationale de marchandises est expressément écartée.
                    </p>
                </div>
                <div>
                    <h2>ANNEXES</h2>
                    <p><strong>Article L211-4 Code de la consommation : </strong>Le vendeur est tenu de livrer un bien
                    conforme au contrat et répond des défauts de conformité existant lors de la délivrance.
                    Il répond également des défauts de conformité résultant de l’emballage, des
                    instructions de montage ou de l’installation lorsque celle-ci a été mise à sa charge par
                    le contrat ou a été réalisée sous sa responsabilité.<br />
                    <strong>Article L211-5 A Code de la consommation : </strong>Pour être conforme au contrat, le bien doit :
                    1° Etre propre à l’usage habituellement attendu d’un bien semblable et, le cas échéant : <br />- correspondre à la description donnée par le vendeur et posséder les qualités que celuici a présentées à l’acheteur sous forme d’échantillon ou de modèle ;
                    <br />- présenter les qualités qu’un acheteur peut légitimement attendre eu égard aux
                    déclarations publiques faites par le vendeur, par le producteur ou par son représentant,
                    notamment dans la publicité ou l’étiquetage ;
                    2° Ou présenter les caractéristiques définies d’un commun accord par les parties ou
                    être propre à tout usage spécial recherché par l’acheteur, porté à la connaissance du
                    vendeur et que ce dernier a accepté.<br />
                    <strong>Article L211-12 Code de la consommation : </strong>L’action résultant du défaut de conformité
                    se prescrit par deux ans à compter de la délivrance du bien.<br />
                    <strong>Article R211-4 Code de la consommation : </strong>Dans les contrats conclus entre des
                    professionnels, d'une part, et, d'autre part, des non-professionnels ou des
                    consommateurs, le professionnel ne peut garantir contractuellement la chose à livrer
                    ou le service à rendre sans mentionner clairement que s'applique, en tout état de cause,
                    la garantie légale qui oblige le vendeur professionnel à garantir l'acheteur contre toutes
                    les conséquences des défauts ou vices cachés de la chose vendue ou du service rendu.<br />
                    <strong>Article 1641 Code civil : </strong>Le vendeur est tenu de la garantie à raison des défauts cachés
                    de la chose vendue qui la rendent impropre à l’usage auquel on la destine, ou qui
                    diminuent tellement cet usage, que l’acheteur ne l’aurait pas acquise, ou n’en aurait
                    donné qu’un moindre prix, s’il les avait connus.<br />
                    <strong>Article 1648 alinéa 1er Code civil : </strong>L’action résultant des vices rédhibitoires doit être
                    intentée par l’acquéreur dans un délai de deux ans à compter de la découverte du vice.
                    </p>
                </div>
            </main>
            <BoutonScrollToTop />
            <Footer />
        </>
    )
}