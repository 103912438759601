/* Modules */
import { useState } from "react"

/* Dépendences */
import FilDAriane from "./FilDAriane"
import Filtres from "./Filtres"

/* Code */
export default function Navigation(props) {
    let cat = props.cat;
    let sousCat = props.sousCat;
    let subCat = props.subCat;
    let elementIsLoading = props.elementIsLoading;

    let [selectedSize, setSelectedSize] = useState(null);

    function handleSize(selectedSize) {
        props.onClick(selectedSize);
        setSelectedSize(selectedSize);
    };

    return (
        <>
            <FilDAriane />
            <Filtres onClick={handleSize} cat={cat} sousCat={sousCat} subCat={subCat} selectedSize={selectedSize} elementIsLoading={elementIsLoading} />
        </>
    )
}