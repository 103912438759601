/* Modules */
import { useEffect } from "react"
import { Link } from 'react-router-dom'

/* Components */
import Header from '../../composants/Header'
import Footer from '../../composants/Footer'

/* Images */
import Illustration from '../../assets/404.svg'

/* Code */
export default function Erreur({texte}) {
    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = "Maison du Revêtement | Page non disponible";
        document.getElementsByTagName("meta")[2].content=`La page consultée n'est pas disponible, veuillez revenir à la page d'accueil via le bouton dédié.`;
    }, []);

    return (
        <>
            <header className="header header_erreur">
                <Header />
            </header>
            <main className="erreur">
                <h1 className="erreur_title">Oups...</h1>
                <br />
                <img src={Illustration} alt="illustration pour indiquer une erreur de page" />
                <br />
                <div className="erreur_texte">
                    {texte}
                </div>
                <button className="bouton_erreur"><Link to="/">PAGE D'ACCUEIL</Link></button>
            </main>
            <Footer />
        </>
    )
}