/* Modules */
import { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"

/* Dépendences */
import { productsService } from "../../_services/products.service"

/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import TexteGammes from "../../composants/TexteGammes"
import CategorieCards from "../../composants/CategoriesCards"
import Plus from "../../composants/Plus"
import Aide from "../../composants/Aide"
import Footer from "../../composants/Footer"
import Erreur from "../autre/Erreur"

/* Code */
export default function Gammes() {
    /* Récupération des données de l'URL */
    let UrlParams  = useParams();
    let gamme = UrlParams.gamme;
    
    /* Remplissage des zones textuelles */
    let [texte, setTexte] = useState("");
    let [titre1, setTitre1] = useState("");
    let [titre2, setTitre2] = useState("");
    let [titre3, setTitre3] = useState("");
    let [titre4, setTitre4] = useState("");
    let [texte1, setTexte1] = useState("");
    let [texte2, setTexte2] = useState("");
    let [texte3, setTexte3] = useState("");
    let [texte4, setTexte4] = useState("");
    let [texte5, setTexte5] = useState("");
    let [texte6, setTexte6] = useState("");
    let [texte7, setTexte7] = useState("");
    let [texte8, setTexte8] = useState("");
    let [texte9, setTexte9] = useState("");
    let [texte10, setTexte10] = useState("");
    let [texte11, setTexte11] = useState("");
    let [texte12, setTexte12] = useState("");
    let [className1, setClassName1] = useState("hidden");
    let [className2, setClassName2] = useState("hidden");

    useEffect(() => {
            if(gamme === "carrelage") {
                setTexte("Réputé pour son entretien minimal, le carrelage saura résister à toutes les épreuves du quotidien. Fort d'un choix important d'aspects, de formes et de couleurs, le carrelage se démarque par sa polyvalence et sa propension à reflèter la lumière.");
                setClassName1("premier");
                setTitre1("Les Tendances");
                setTitre2("du Moment");
                setTexte1("Imitation marbre : Le marbre et les carreaux qui imitent cette pierre naturelle sont très en vogue. Les carreaux marbrés peuvent ajouter une touche de sophistication et d'élégance à une pièce.");
                setTexte2("Imitation béton : Le béton est un matériau qui donnera un look industriel et contemporain. Les carreaux qui imitent le béton peuvent être utilisés pour créer un effet de texture brut tout en restant moderne.");
                setTexte3("Imitation bois : Les carreaux qui imitent le bois offrent l'apparence chaleureuse et naturelle du bois, sans les inconvénients de l'entretien.");
                setTexte4("Les grands formats : Les carreaux de grand format peuvent ajouter de l'ampleur et de la profondeur à une pièce, tout en minimisant les joints entre les carreaux.");
                setTexte5("Effet 3D : Les carreaux en relief peuvent ajouter de la texture et de la dimension à une pièce. Les motifs géométriques en 3D apporteront une touche d'originalité à votre intérieur.");
                setTexte6("Couleurs neutres : Les couleurs neutres, telles que le blanc, le gris et le beige, restent très populaires en matière de carrelage. Ces couleurs intemporelles sont faciles à assortir avec différents styles de décoration d'intérieur.");
                setClassName2("hidden");
                setTitre3(""); 
                setTitre4("");
                setTexte7("")
                setTexte8("");
                setTexte9("");
                setTexte10("");
                setTexte11("");
                setTexte12("");
            } else if(gamme === "parquet") {
                setTexte("Opter pour un parquet c'est privilégier des matériaux authentiques, naturels, intemporels, porteurs de valeurs sûres. Image de noblesse et de l'histoire de France, le parquet est utilisé depuis des siècles. Réputé pour sa robustesse, il conserve son image traditionnelle tout en s’adaptant aux tendances du moment.")
                setClassName1("premier");
                setTitre1("Le Chêne");
                setTitre2("Français");
                setTexte1("");
                setTexte2("");
                setTexte3("Maison du Revêtement propose des parquets dont l'essence est le chêne français, choisi minutieusement par nos usines.");
                setTexte4("En raison des conditions climatiques idéales pour la croissance des arbres, le chêne français est naturellement résistant et durable.");
                setTexte5("");
                setTexte6("");
                setClassName2("second");
                setTitre3("Les Tendances"); 
                setTitre4("du Moment");
                setTexte7("");
                setTexte8("Les bois clairs : Les parquets en bois clair, comme le chêne blanchi ou le hêtre apporteront une touche de luminosité, de modernité et d'élégance à n'importe quelle pièce.");
                setTexte9("Les larges lames : Les lames de parquet plus larges connaissent ces dernières années un vrai essor, car elle donnent un aspect plus moderne et plus spacieux à un volume.");
                setTexte10("Les parquets texturés : Les parquets avec des textures, comme des motifs de chevrons ou des nœuds naturels, ajoutent une touche de caractère et de personnalité à votre intérieur.");
                setTexte11("Les finitions mates : Ces finitions donnent une touche plus naturelle et plus discrète au parquet, tout en étant plus résistantes aux rayures et aux taches.");
                setTexte12("");
            } else if (gamme === "sanitaire"){
                setTexte("Pour compléter vos projets d'aménagement, quoi de mieux qu'habiller vos salles d'eau de pièces d'exception de robineterie, de douche ou encore de baignoire. Vous trouverez forcément des éléments sanitaire pour créer un atmosphère chaleureux et luxieux, mis en avant par vos revêtements.");
                setClassName1("premier");
                setTitre1("Les Tendances");
                setTitre2("du Moment");
                setTexte1("");
                setTexte2("Les salles de bain minimaliste : Les tendances actuelles se concentrent sur les apparences modernes et minimaliste pour les salles de bain, en s’appuyant sur des lignes épurées et des formes simples.");
                setTexte3("Les vasques sur pied : Très en vogue pour leur look rétro et leur capacité à s'intégrer dans des salles de bains modernes et traditionnelles.");
                setTexte4("Les couleurs neutres : En terme de coloris, les couleurs neutres telles que le blanc, le beige, le gris et le noir sont très populaires en ce moment. Elles donnent une touche intemporelle et élégante.");
                setTexte5("Les carreaux en hexagone : Cette forme est appréciée pour sa capacité à ajouter une touche de géométrie aux salles de bain.");
                setTexte6("");
                setClassName2("hidden");
                setTitre3(""); 
                setTitre4("");
                setTexte7("")
                setTexte8("");
                setTexte9("");
                setTexte10("");
                setTexte11("");
                setTexte12("");
            } else {
                setTexte("Cette gamme de produits n'existe pas !");
                setClassName1("hidden");
                setClassName2("hidden");
            }
    }, [gamme])

    /* Récupération des infos liés aux catégories de gammes */
    let [cat, setCat] = useState(null);
    let [pageIsLoading, setPageIsLoading] = useState(true);

    useEffect(() => {
        productsService.getAllCats()
        .then((res) => {
            setCat(res.data.find(cat => cat.name === gamme.charAt(0).toUpperCase() + gamme.slice(1)));
            setPageIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }, [gamme]);

    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = `${gamme.charAt(0).toUpperCase() + gamme.slice(1)} d'exception | Maison du Revêtement`;
        document.getElementsByTagName("meta")[2].content=`Découvrez les tendences du moment concernant le ${gamme} ainsi que les différentes gammes de ${gamme} proposées par Maison du Rêvetement.`;
    }, [gamme]);

    return (
        <>
            { pageIsLoading ?
                <div className="page-spinner">
                    <div className="page-spinner_interieur">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            :
            <>
                { cat !== undefined ?
                    <>
                        <header className={`header header_${gamme}`}>
                            <Header />
                            <TitreGammes titre={gamme} texte={texte} />
                        </header>
                        <main className="gammes">
                            <section className="gammes_categories">
                            { cat.childs.length > 0 && cat.childs.map((child) => {
                                return (
                                    <CategorieCards path={`/${gamme}/${child.name.replaceAll(' ', '-').toLowerCase()}/pas-de-sous-categorie/stock-et-commande`} titre={child.name} key={child.id}/>
                                )
                            })}
                            </section>
                            <section className="gammes_boutons">
                                <button className="articlesEnStock">
                                    <Link to={`/promo/${gamme}`} className="boutonStock" key="promo">NOS {gamme.toUpperCase()}S EN PROMO</Link>
                                </button>
                                <button className="articlesEnStock">
                                    <Link to={`/en-stock/${gamme}`} className="boutonStock" key="dispo">NOS {gamme.toUpperCase()}S EN STOCK</Link>
                                </button>
                            </section>
                            <TexteGammes className={className1} titre1={titre1} titre2={titre2} texte1={texte1} texte2={texte2} texte3={texte3} texte4={texte4} texte5={texte5} texte6={texte6} />
                            <TexteGammes className={className2} titre1={titre3} titre2={titre4} texte1={texte7} texte2={texte8} texte3={texte9} texte4={texte10} texte5={texte11} texte6={texte12} />
                        </main>
                        <Plus />
                        <Aide catName={cat.name} />
                        <Footer />
                    </>
                    :
                    <Erreur texte={"Cette gamme d'articles semble ne pas exister !"} />
                }
            </>
            }
        </>
    )
}