/* Modules */
import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'

/* Dépendences */
import { productsService } from "../_services/products.service"

/* Code */
export default function BarreDeRecherche() {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();

    let [cards, setCards] = useState([]);
    let [value, setValue] = useState("");
    let [elementIsLoading, setElementIsLoading] = useState(false);

    /* Récupération des cards depuis le localStorage */
    let items = JSON.parse(localStorage.getItem('cards'));

    /* Fonction de récupération des cards */
    function getAllCards(){
        setElementIsLoading(true);
        productsService.getAllCards({})
        .then((res) => {
            setCards(res.data.products);
            localStorage.setItem('cards', JSON.stringify(res.data.products));
            setElementIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    };

    /* On lance cette fonction à la fin du chargement de la page, pour initialiser les cards */
    // window.addEventListener('load', function () {
    //     if(items && cards.length === 0) {
    //         setCards(items);
    //     } else {
    //         getAllCards();
    //     }
    // });

    /* On lance cette fonction à l'initialisation du composant uniquement, pour initialiser les cards */
    useEffect(() => {
        if (items && cards.length === 0) {
            setCards(items);
        } else {
            if(UrlParams.recherche === undefined) {
                getAllCards();
            }
        }
    }, []);

    /* Utilisation du module Fuse pour avoir une barre de recherche plus souple */
    const fuseOptions = {
        includeScore: true,
        threshold: 0.4, // Adjust this value to control the fuzziness (0 = exact match, 1 = match anything)
        keys: ['barcode', 'category.display_name', 'category[1].display_name']
    };  
    const fuse = new Fuse(cards, fuseOptions);
    let resultat = fuse.search(value).map(result => result.item);

    /* Cacher le bloc au click sur une card */
    function handleClickCard() {
        const box = document.getElementById('box');
        box.classList.add("hideSearchingBar");
    }

    /* Ouvrir la page "Recherche" à la pression du bouton "loupe" */
    const navigate = useNavigate();

    function Searching() {
        if (elementIsLoading === false) {
            navigate(`../recherche/valeur=${value}`, { replace: true });
        }
    }

    /* Ouvrir la page "Recherche" à la pression du bouton "Entrée" */
    let input = document.getElementById("barreDeRecherche_input");

    input && input.addEventListener("keyup", (event) => {
        if (event.key === "Enter" && elementIsLoading === true) {
            event.preventDefault();
        }
        else if (event.key === "Enter" && elementIsLoading === false) {
            let newValue = value.replace(" ", "-");
            navigate(`../recherche/valeur=${newValue}`, { replace: true });
        }
    });

    return (
        <div className="recherche hideSearchingBar" id="box">
            <div className="recherche_conteneur" id="box_second">
                <div className="recherche_conteneur_blocUn">
                    <div className="recherche_conteneur_barreDeRecherche">
                        <input type="search" name="recherche" onInput={evt => setValue(evt.target.value)} className="recherche_conteneur_barreDeRecherche_input" id="barreDeRecherche_input" placeholder="Tapez votre recherche ici !" />
                        <i onClick={Searching} className="recherche_conteneur_barreDeRecherche_loupe fa-solid fa-magnifying-glass"></i>
                        { elementIsLoading ?
                            <div className="spinner recherche_conteneur_barreDeRecherche_spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        :
                            null 
                        }
                    </div>
                </div>
                <div className="recherche_conteneur_info">
                    <p>Retrouver un produit par son nom ou par sa catégorie d'article !</p>
                    <p>Essayez par exemple : "carrelage", "marbre", "imitation terrazzo", "massif" ou "colle"</p>
                </div>
                { value.length > 0 &&
                    <div className="recherche_conteneur_resultat">
                        { resultat.sort((a, b) => {
                                return a.barcode.localeCompare(b.barcode);
                            }).map((card) => {
                            return (
                                card.id === 74619 ?
                                    null
                                :
                                    <div className="cardRecherche" key={card.id} onClick={handleClickCard}>
                                        <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardRecherche_photo">
                                            <img src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            { card.additional_photo !== false ?
                                                <img className="cardRecherche_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            :
                                                <img className="cardRecherche_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            }
                                            { card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                                <div className="bullePromo">PRIX USINE</div>
                                            :
                                                <>
                                                    { card.discount !== 0 ?
                                                        <div className="bullePromo">- {card.discount} %</div>     
                                                    :
                                                        null
                                                    }
                                                </>
                                            }
                                            { card.stock === true || card.quantityInStock >= 10 ?
                                                <div className="bulleStock">en stock</div>
                                                :
                                                <></>
                                            }
                                        </Link>
                                        <div className="cardRecherche_info">
                                            <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardRecherche_info_nom">
                                                {card.barcode}
                                            </Link>
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}