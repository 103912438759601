/* Modules */
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom"

/* Images */
import DrapeauFrance from "../assets_opti/produits/drapeauFrance_opti.webp"
import DrapeauItalie from "../assets_opti/produits/drapeauItalie_opti.webp"
import DrapeauEspagne from "../assets_opti/produits/drapeauEspagne_opti.webp"

/* Code */
export default function CardArticle(props) {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();
    let gamme = UrlParams.gamme;
    let produit = UrlParams.produit;
    let sousCategorie = UrlParams.sousCategorie;
    let stock = UrlParams.stock;

    /* Récupération des données par les props */
    let cards = props.cards;
    let nbTotal = props.nbTotal;

    const navigate = useNavigate();

    function setDispo() {
        navigate(`../${gamme}/${produit}/${sousCategorie}/stock-et-commande`, { replace: true });
    }

    return (
        <>
            { cards && cards.length > 0 ?
            <div className="recap">
                <span className="recap_nbArticles" id="nb_articles">{cards.length + " articles affichés / " + nbTotal }{ stock === "stock-et-commande" ? " qui répondent à vos attentes" : " disponibles immédiatement"}</span>
                <div className={`recap_stockage ${ stock === "stock-et-commande" ? "" : "visible"}`}>
                    <div className="iconeX" onClick={setDispo}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <span>en stock</span>
                </div>
            </div>
            :
                <span className="recap" id="nb_articles">Aucun article ne correspond à votre recherche ! Nous ajouterons des produits prochainement.</span>
            }
            <section className="listeProduits">
                { cards && cards.map((card) => {
                    return (
                        card.id === 74619 ?
                            null
                        :
                            <div className="cardArticle" key={card.id}>
                                <Link to={`/${gamme}/${produit}/${sousCategorie}/article/id=${card.id}`} className="cardArticle_photo">
                                    <img className="cardArticle_photo_un" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                    { card.additional_photo !== false ?
                                        <img className="cardArticle_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                    :
                                        <img className="cardArticle_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                    }
                                    { card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                        <div className="bullePromo">PRIX USINE</div>
                                    :
                                        <>
                                            { card.discount !== 0 ?
                                                <div className="bullePromo">- {card.discount} %</div>     
                                            :
                                                null
                                            }
                                        </>
                                    }
                                    { card.stock === true || card.quantityInStock >= 10 ?
                                        <div className="bulleStock">en stock</div>
                                        :
                                        null
                                    }
                                    { card.provenance === "F" ?
                                        <div className="drapeau">
                                            <img src={DrapeauFrance} alt="drapeau de la France"/>
                                        </div>        
                                    :
                                        null
                                    }
                                    { card.provenance === "E" ?
                                        <div className="drapeau">
                                            <img src={DrapeauEspagne} alt="drapeau de l'Espagne"/>
                                        </div>        
                                    :
                                        null
                                    }
                                    { card.provenance === "I" ?
                                        <div className="drapeau">
                                            <img src={DrapeauItalie} alt="drapeau de l'Italie"/>
                                        </div>        
                                    :
                                        null
                                    }
                                </Link>
                                <div className="cardArticle_info">
                                    <Link to={`/${gamme}/${produit}/${sousCategorie}/article/id=${card.id}`} className="cardArticle_info_nom">
                                        {card.barcode}
                                    </Link>
                                    { card.category.display_name.split(' / ')[2] !== null ?
                                        <div className="cardArticle_info_category">
                                            {card.category.display_name.split(' / ')[2]}
                                        </div>
                                    :
                                    null
                                    }
                                    { card.discount !== 0 || card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                        <div className="cardArticle_info_prix">
                                            <p className="cardArticle_info_prix_gauche colorRed">{((card.price_without_taxes * (1 - (card.discount / 100)))).toFixed(2)} €HT{ produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" }</p>
                                            <p className="cardArticle_info_prix_droite colorRed">({((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2)} €TTC{ produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" })</p>
                                        </div>
                                    :
                                        <div className="cardArticle_info_prix">
                                            <p className="cardArticle_info_prix_gauche">{(card.price_without_taxes).toFixed(2)} €HT{ produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" }</p>
                                            <p className="cardArticle_info_prix_droite">({(card.price_without_taxes * 1.2).toFixed(2)} €TTC{ produit === "lame-murale-polystyrène" || produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" })</p>
                                        </div>
                                    }
                                </div>
                            </div>
                    )
                })}
            </section>
        </>
    )
}