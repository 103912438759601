/* Modules */
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

/* Images */
import Mdr from '../assets/mdr_logonoir.png'

/* Code */
export default function Filtres(props) {
    /* Récupération des données de l'URL */
    let UrlParams  = useParams();
    let gamme = UrlParams.gamme;
    let produit = UrlParams.produit;
    let sousCategorie = UrlParams.sousCategorie;
    let stock = UrlParams.stock;

    let cat = props.cat;
    let sousCat = props.sousCat;
    let subCat = props.subCat;
    let elementIsLoading = props.elementIsLoading;

    let [selectedSize, setSelectedSize] = useState(null);
    
    const navigate = useNavigate();

    /* Gestion des changemens de l'URL au click */
    function changeCategory(cat) {
        navigate(`../${gamme}/${cat}/pas-de-sous-categorie/${stock}`, { replace: true });

        setSelectedSize(null);
        let otherButtonsSize = document.getElementsByClassName("boutonsFiltreSize");
        let otherButtonsSizeArray = [...otherButtonsSize];
        otherButtonsSizeArray.map(button => button.classList.remove("catSelected"));

        let fleche = document.getElementById('fleche');
        let filtres = document.getElementById('filtres');
        fleche.style.transform = "rotate(0deg)";
        filtres.style.display = "none";
        let flecheDeux = document.getElementById('flecheDeux');
        let filtresDeux = document.getElementById('filtresDeux');
        flecheDeux.style.transform = "rotate(0deg)";
        filtresDeux.style.display = "none";
        let flecheTrois = document.getElementById('flecheTrois');
        let filtresTrois = document.getElementById('filtresTrois');
        if(flecheTrois && filtresTrois) {
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        }
    }

    function changeSubCategory(subCat) {
        navigate(`../${gamme}/${produit}/${subCat}/${stock}`, { replace: true });

        setSelectedSize(null);
        let otherButtonsSize = document.getElementsByClassName("boutonsFiltreSize");
        let otherButtonsSizeArray = [...otherButtonsSize];
        otherButtonsSizeArray.map(button => button.classList.remove("catSelected"));

        let fleche = document.getElementById('fleche');
        let filtres = document.getElementById('filtres');
        fleche.style.transform = "rotate(0deg)";
        filtres.style.display = "none";
        let flecheDeux = document.getElementById('flecheDeux');
        let filtresDeux = document.getElementById('filtresDeux');
        flecheDeux.style.transform = "rotate(0deg)";
        filtresDeux.style.display = "none";
        let flecheTrois = document.getElementById('flecheTrois');
        let filtresTrois = document.getElementById('filtresTrois');
        if(flecheTrois && filtresTrois) {
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        }
    }

    /* Gestion de l'affichage des filtres "catégories" */
    function displayCategorySelected(category) {
        let otherButtons = document.getElementsByClassName("boutonsFiltre");
        let otherButtonsArray = [...otherButtons];
        let selectedButton = document.getElementById(`bouton_${category}`);
        if(selectedButton) {
            otherButtonsArray.map(button => button.classList.remove("catSelected"));
            selectedButton.classList.add("catSelected");
        }
    }

    /* Gestion de l'affichage des filtres "sous-catégories" */
    function displaySubCategorySelected(subCategory) {
        let selectedButton = document.getElementById(`bouton_${subCategory}`);
        if(selectedButton) {
            selectedButton.classList.add("catSelected");
        }
    }

    /* Lancer les 2 fonctions au démarrage de la page */
    useEffect(() => {
        displayCategorySelected(produit);
        if(sousCategorie) {
            displaySubCategorySelected(sousCategorie);
        }
    }, [cat, sousCat, produit, sousCategorie])

    function changeSize(size) {
        let otherButtonsSize = document.getElementsByClassName("boutonsFiltreSize");
        let otherButtonsSizeArray = [...otherButtonsSize];
        otherButtonsSizeArray.map(button => button.classList.remove("catSelected"));

        if(selectedSize && selectedSize.replace(" ", "") === size) {
            setSelectedSize(undefined);
        } else {
            setSelectedSize(`${size}`);
            let selectedSizeButton = document.getElementById(`bouton_${size}`);
            selectedSizeButton.classList.add("catSelected");
        }

        let fleche = document.getElementById('fleche');
        let filtres = document.getElementById('filtres');
        fleche.style.transform = "rotate(0deg)";
        filtres.style.display = "none";
        let flecheDeux = document.getElementById('flecheDeux');
        let filtresDeux = document.getElementById('filtresDeux');
        flecheDeux.style.transform = "rotate(0deg)";
        filtresDeux.style.display = "none";
        let flecheTrois = document.getElementById('flecheTrois');
        let filtresTrois = document.getElementById('filtresTrois');
        if(flecheTrois && filtresTrois) {
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        }
    }

    function PropsSize() {
        props.onClick(selectedSize);
    }

    useEffect(() => {
        PropsSize();
    }, [selectedSize])


    /* Gestion du filtre "Tailles / Dimensions" */
    let items = JSON.parse(localStorage.getItem('cards'));
    let itemsByCat = [];
    let tailles = [];

    items && items.map(i => {
        if(sousCategorie !== "pas-de-sous-categorie") {
            if(i.category.display_name.includes(subCat.display_name)) {
                return itemsByCat.push(i);
            } else {
                return null;
            }
        } else {
            if(i.category.display_name.includes(sousCat.display_name)) {
                return itemsByCat.push(i);
            } else {
                return null;
            }
        }
    });

    itemsByCat && itemsByCat.map(i => {
        if (!tailles.includes(i.size) && i.size !== false) {
            return tailles.push(i.size);
        } else {
            return null;
        }
    });

    let sortingTailles = [];

    sortingTailles = tailles.map(function (e) {
        return {value1 : e.split('x')[0].replace(",","."), value2 : 'x', value3: e.split('x')[1].replace(",",".")};
    }).sort(function(a, b) {
        if(a.value1 === b.value1) {
            return a.value3 - b.value3;
        } else {
            return a.value1 - b.value1;
        }
    })

    let joinedTailles = sortingTailles.map(st => 
        Object.values(st).join("").replace(".",",")
    )

    /* Gestion du filtre "en stock" */
    function displayDispo() {
        if(stock === "stock-et-commande"){
            navigate(`../${gamme}/${produit}/${sousCategorie}/en-stock`, { replace: true });
        } else {
            navigate(`../${gamme}/${produit}/${sousCategorie}/stock-et-commande`, { replace: true });
        }

        let fleche = document.getElementById('fleche');
        let filtres = document.getElementById('filtres');
        fleche.style.transform = "rotate(0deg)";
        filtres.style.display = "none";
        let flecheDeux = document.getElementById('flecheDeux');
        let filtresDeux = document.getElementById('filtresDeux');
        flecheDeux.style.transform = "rotate(0deg)";
        filtresDeux.style.display = "none";
        let flecheTrois = document.getElementById('flecheTrois');
        let filtresTrois = document.getElementById('filtresTrois');
        if(flecheTrois && filtresTrois) {
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        }
    }

    useEffect(() => {
        let boutonStock = document.getElementById('boutonStock');
        if(stock === "stock-et-commande"){
            boutonStock.classList.add("stockSelected");
        } else {
            boutonStock.classList.remove("stockSelected");
        }
    }, [stock])

    /* Gestion de l'affichage des catégories */
    let actif = false;
    
    function affichageCategories() {
        let fleche = document.getElementById('fleche');
        let filtres = document.getElementById('filtres');

        if(actif === false) {
            actif = true;
            fleche.style.transform = "rotate(90deg)";
            filtres.style.display = "flex";
        } else {
            actif = false;
            fleche.style.transform = "rotate(0deg)";
            filtres.style.display = "none";
        }
    }

    let actifDeux = false;

    function affichageSousCategories() {
        let flecheDeux = document.getElementById('flecheDeux');
        let filtresDeux = document.getElementById('filtresDeux');

        if(actifDeux === false) {
            actifDeux = true;
            flecheDeux.style.transform = "rotate(90deg)";
            filtresDeux.style.display = "flex";
        } else {
            actifDeux = false;
            flecheDeux.style.transform = "rotat)";
            filtresDeux.style.display = "none";
        }

    }

    let actifTrois = false;

    function affichageSize() {
        let flecheTrois = document.getElementById('flecheTrois');
        let filtresTrois = document.getElementById('filtresTrois');
        
        if(actifTrois === false) {
            actifTrois = true;
            flecheTrois.style.transform = "rotate(90deg)";
            filtresTrois.style.display = "flex";
        } else {
            actifTrois = false;
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        }
    }

    /* Au scroll, on ferme les filtres
    let fleche = document.getElementById('fleche');
    let filtres = document.getElementById('filtres');
    let flecheDeux = document.getElementById('flecheDeux');
    let filtresDeux = document.getElementById('filtresDeux');
    let flecheTrois = document.getElementById('flecheTrois');
    let filtresTrois = document.getElementById('filtresTrois');

    window.addEventListener("scroll", () => {
        if(fleche && filtres) {
            fleche.style.transform = "rotate(0deg)";
            filtres.style.display = "none";
        }
        if(flecheDeux && filtresDeux) {
            flecheDeux.style.transform = "rotate(0deg)";
            filtresDeux.style.display = "none";
        }
        if(flecheTrois && filtresTrois) {
            flecheTrois.style.transform = "rotate(0deg)";
            filtresTrois.style.display = "none";
        } 
    }); */

    return (
        <div className="filtres">
            <div className="filtres_bloc">
                <div className="filtres_produit">
                    <div className="filtres_produit_titre"onClick={affichageCategories}>
                        <h2>Catégories de {cat.name}</h2>
                        <button className="filtres_produit_titre_fleche" id="fleche" aria-label="Bouton de remontée en haut de page"><FontAwesomeIcon icon={faChevronRight} size="sm" /></button>
                    </div>
                    <ul id="filtres">
                        { cat.childs && cat.childs.map((sousCat) => {
                            return (
                                <li id={`bouton_${sousCat.name.toLowerCase().split(" ").join("-")}`} className="boutonsFiltre" key={sousCat.id}>
                                    <div className="boutonsFiltre_entier" onClick={() => changeCategory(sousCat.name.toLowerCase().split(" ").join("-"))}>
                                        <span className="boutonsFiltre_icones"><img alt={"Catégorie de " + sousCat.name} src={sousCat.img_url ? sousCat.img_url : Mdr}></img></span>
                                        <span className="boutonsFiltre_filtre">{sousCat.name}</span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="filtres_produit">
                    <div className="filtres_produit_titre" onClick={affichageSousCategories}>
                        <h2>Sous-catégories pour {sousCat.name}</h2>
                        <button className="filtres_produit_titre_fleche" id="flecheDeux" aria-label="Bouton de remontée en haut de page"><FontAwesomeIcon icon={faChevronRight} size="sm" /></button>
                    </div>
                    <ul id="filtresDeux">
                        { sousCat.childs && sousCat.childs.map((subCat) => {
                            return (
                                <li id={`bouton_${subCat.name.toLowerCase().split(" ").join("-")}`} className="boutonsFiltre" key={subCat.id}>
                                    <div className="boutonsFiltre_entier" onClick={() => changeSubCategory(subCat.name.toLowerCase().split(" ").join("-"))}>
                                        <span className="boutonsFiltre_icones"><img alt={"Catégorie de " + subCat.name} src={subCat.img_url ? subCat.img_url : Mdr}></img></span>
                                        <span className="boutonsFiltre_filtre">{subCat.name}</span>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                { gamme !== "sanitaire" && joinedTailles.length > 0 &&
                <>
                    <div className="filtres_produit">
                        <div className="filtres_produit_titre" onClick={affichageSize}>
                            <h2>{ gamme === "carrelage" ? "Tailles" : "Dimensions" }</h2>
                            <button className="filtres_produit_titre_fleche" id="flecheTrois" aria-label="Bouton de remontée en haut de page"><FontAwesomeIcon icon={faChevronRight} size="sm" /></button>
                        </div>
                        <ul id="filtresTrois">
                            { joinedTailles.map((size) => {
                                return (
                                    <li id={`bouton_${size}`} className="boutonsFiltreSize" key={size}>
                                        <div className="boutonsFiltre_entier" onClick={() => changeSize(size)}>
                                            <span className="boutonsFiltre_icones"><img alt={"Taille : " + size} src={Mdr}></img></span>
                                            <span className="boutonsFiltre_filtre">{size}</span>
                                        </div>
                                    </li>
                                )
                            }) }
                        </ul>
                    </div>
                </>
                }
                <div className="filtres_produit filtres_produit_bouton">
                    <button id="boutonStock" className={`boutonsFiltre_enStock ${stock === "en-stock" ? "stockOui" : "stockNon" }`} onClick={displayDispo} key="dispo">{stock === "en-stock" ? "EN STOCK" : "VOIR DISPO" }</button>
                </div>
            </div>
            <div className="filtres_chargementStock">
                { elementIsLoading ?
                    <div className="spinner filtres_spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                :
                    null 
                }
            </div>
        </div>
    )
}