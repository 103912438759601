/* Modules */
import { Link } from 'react-router-dom'

/* Code */
export default function CategorieCards({path, titre}) {
    return (
        <div className={"gammes_categories_card gammes_categories_card_" + titre.replaceAll(' ', '-').replaceAll('&', 'et').toLowerCase()} >
            <Link to={path}>
                <h2>{titre}</h2>
            </Link>
        </div>
    )
}