/* Modules */
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

/* Code */
export default function AfficherPlusDeProduits(props) {
    let nbProduits = props.nbProduits;
    let nbTotal = props.nbTotal;
    let [pagesAffichés, setPagesAffichés] = useState(0);
    let [pagesTotales, setPagesTotales] = useState(0);
    let elementIsLoading = props.elementIsLoading;
    
    let [produitsMax, setProduitsMax] = useState(false);

    /* On détermine s'il reste encore des produits à afficher */
    if (nbProduits >= nbTotal) {
        produitsMax = true;
    } else {
        produitsMax = false;
    }

    /* On lance cette fonction au lancement de la page */
    useEffect(() => {
        setPagesAffichés(
            nbProduits % 32 === 0 ?
                nbProduits / 32
            :
                Math.trunc(nbProduits / 32) + 1
        );
        setPagesTotales(
            nbTotal % 32 === 0 ?
            nbTotal / 32
        :
            Math.trunc(nbTotal / 32) + 1
        )
    }, [nbProduits, nbTotal]);

    /* On affiche plus de produits s'il en reste */
    function afficherPlus() {
        if (!produitsMax) {
            if (nbProduits + 32 <= nbTotal) {
                nbProduits += 32;
                setProduitsMax(false);
            } else {
                nbProduits = nbTotal;
                setProduitsMax(true);
            }
            props.onClick(nbProduits);
        }
    };

    return (
        <div className="plusProduits">
            { produitsMax ?
                <div className="plusProduits_seul">
                    <button className="plusProduits_bouton bouton_fini">TOUS LES PRODUITS SONT AFFICHÉS !</button>
                </div>
            :   
                <div className="plusProduits_ensemble">
                    <div className="plusProduits_gauche"></div>
                    <div className="plusProduits_centre" onClick={afficherPlus}>
                        <button className="plusProduits_bouton bouton_encore" >AFFICHER PLUS</button>
                        <FontAwesomeIcon icon={faChevronDown} className="plusProduits_fleche" size="2xl" style={{color: "#28292A"}} />
                    </div>
                    <div className="plusProduits_droite">
                        { pagesAffichés }
                        { pagesAffichés === 1 ?
                            " page affichée / "
                        :
                            " pages affichées / "
                        }
                        { pagesTotales + " au total" }
                    </div>
                </div>
            }
            { elementIsLoading ?
                <div className="spinner plusProduits_spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            :
                null
            }
        </div>
    )
}