/* Dépendences */
import Axios from './caller.service';

/* Code */
function getAllCards(searchFilter) {
    let queryString = 'api/public/articles/search';

    if(searchFilter.page) {
        queryString += '?page='+ searchFilter.page
    } else {
        queryString += '?page='+ 1
    }

    if(searchFilter.limit){
        queryString += '&limit='+ searchFilter.limit 
    } else {
        queryString += '&limit=1000'
    }

    if(searchFilter.categId) {
        queryString += '&categId='+ searchFilter.categId
    }

    if(searchFilter.available !== null && searchFilter.available !== undefined) {
        queryString += '&available=' + searchFilter.available
    }

    if(searchFilter.size) {
        queryString += '&size='+ searchFilter.size
    }

    if(searchFilter.carousel) {
        queryString += '&carousel='+ searchFilter.carousel
    }

    return Axios.get(queryString, { withCredentials: true })
}

function getOneCard(id) {
    return Axios.get('api/public/articles/' + id, { withCredentials: true })
}

function getAllCats() {
    return Axios.get('api/public/articles/getCategories', { withCredentials: true })
}

/* function getAllAtts(searchFilter) {
    let queryString = 'api/public/articles/getAttributes';

    if(searchFilter.categoryId) {
        queryString += '?categoryId='+ searchFilter.categoryId
    }

    return Axios.get(queryString, { withCredentials: true })
} */

function addToCart(data) {
    return Axios.post('api/public/cart/addProduct', data, {
        withCredentials : true
    })
}

function getOrderId() {
    Axios.get('api/public/cart/getOrderId', { withCredentials: true })
        .then((res) => {
            if (res.data.id) {
                document.location = 'https://maisondurevetement.odoo.com/shop/cart/' + res.data.id;
            } else {
                alert("Your shopping cart is empty, please add products.");
                document.location = 'https://maisondurevetement.com/promo/carrelage-et-parquet';
            }
        })
        .catch((err) => {
            console.log(err);
        })
}

export const productsService = { 
    getAllCards, getOneCard, getAllCats, addToCart, getOrderId
};

// getAllAtts