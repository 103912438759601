/* Modules */
import { useEffect } from "react"
import { Link } from 'react-router-dom'

/* Components */
import Header from '../../composants/Header'
import Footer from '../../composants/Footer'

/* Code */
export default function EspaceClient() {
    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = "Espace Client en construction | Maison du Revêtement";
        document.getElementsByTagName("meta")[2].content=`Maison du Revêtement mettra à disposition très prochainement un espace personnel vous permettant de consulter votre historique de commandes et de gérer vos adresses de livraison et de facturation.`;
    }, []);

    return (
        <>
            <header className="header header_espaceClient">
                <Header />
            </header>
            <main className="espaceClient">
                <div className="espaceClient_construction">
                    <h1>Le site est en cours de lancement !</h1>
                    <p>Cette page est en cours de construction, nous vous remercions pour votre patience.</p>
                    <p>Maison du Revêtement mettra à disposition très prochainement un espace personnel vous permettant de consulter votre historique de commandes et de gérer vos adresses de livraison et de facturation.</p>
                    <button className="bouton_espaceClient"><Link to="/">PAGE D'ACCUEIL</Link></button>
                </div>
            </main>
            <Footer />
        </>

    )
}