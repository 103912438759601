/* Modules */
import React, { useEffect, useState } from "react"

/* Dépendences */
import { productsService } from "../_services/products.service"

/* Composants */
import Header from '../composants/Header'
import Gallery from "../composants/Gallery"
import MisEnAvant from '../composants/MisEnAvant'
import Plus from "../composants/Plus"
import Footer from '../composants/Footer'

/* Images */
import SectionPresentation from "../assets_opti/home/section-presentation_opti.webp"
import SectionAccompagnement from "../assets_opti/home/section-accompagnement_opti.webp"
import SectionPro from "../assets_opti/home/section-pro_opti.webp"
import ShowroomParis from "../assets_opti/home/showroom2_opti.webp"
import ShowroomVilleneuve from "../assets_opti/home/showroom3_opti.webp"

/* Code */
export default function Home() {
    /* Réduction du header lors d'un scroll
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20);
        });
    }, []); */

    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = "Maison du Revêtement | Carrelage & Parquet d'exception";
        document.getElementsByTagName("meta")[2].content="Maison du revêtement, entreprise spécialisée dans le revêtement de murs et de sols, est située en Ile-de-France et propose des carrelages et parquets d'exception.";
    }, []);

    /* Fonctions pour récupérer les cards du carousel "Promo" */
    let [promoCarouselCards, setPromoCarouselCards] = useState(null);
    let promoStorageCards = JSON.parse(localStorage.getItem('PromoCarouselCards'));

    function getAllCardsforPromoCarousel(){
        productsService.getAllCards({
            carousel: "Promo"
        })
        .then((res) => {
            localStorage.setItem('PromoCarouselCards', JSON.stringify(res.data.products));
            setPromoCarouselCards(res.data.products);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    /* Fonctions pour récupérer les cards du carousel "Stock" */
    let [stockCarouselCards, setStockCarouselCards] = useState(null);
    let stockStorageCards = JSON.parse(localStorage.getItem('StockCarouselCards'));

    function getAllCardsforStockCarousel(){
        productsService.getAllCards({
            carousel: "Stock"
        })
        .then((res) => {
            localStorage.setItem('StockCarouselCards', JSON.stringify(res.data.products));
            setStockCarouselCards(res.data.products);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    /* Lancement des deux fonctions pour l'affichage du bloc "misEnAvant" */
    useEffect(() => {
        getAllCardsforPromoCarousel();
        getAllCardsforStockCarousel();
    }, []);

    /* Lancement de cette fonction à la fin du chargement de la page initiale, pour stocker toutes les cards dans le localStorage */
    window.addEventListener('load', function () {
        if(promoStorageCards) {
            setPromoCarouselCards(promoStorageCards);
        } else {
            getAllCardsforPromoCarousel();
        }

        if(stockStorageCards) {
            setStockCarouselCards(stockStorageCards);
        } else {
            getAllCardsforStockCarousel();
        }
    });

    /* Fonctions au click pour afficher l'image principale en grand */
    const [showFullScreenParis, setShowFullScreenParis] = useState(false);
    const handleImageClickParis = () => {
        setShowFullScreenParis(true);
    };
    const handleCloseFullScreenParis = () => {
        setShowFullScreenParis(false);
    };

    const [showFullScreenVG, setShowFullScreenVG] = useState(false);
    const handleImageClickVG = () => {
        setShowFullScreenVG(true);
    };
    const handleCloseFullScreenVG = () => {
        setShowFullScreenVG(false);
    };

    return (
        <div className="bodyMain">  
            <header className={`header header_home`}>
                <Header page="Home" />
            </header>
            <main className="mainHome">
                <Gallery />
                <MisEnAvant promoCarouselCards={promoCarouselCards} stockCarouselCards={stockCarouselCards} />
                <Plus />
                <section className="showroom" id="contact">
                    <h2 className="showroom_titre">NOS SHOWROOMS</h2>
                    <div className="showroom_bloc">
                        { showFullScreenParis ?
                            <div className="showroom_bloc_deux_fullscreen">
                                <img src={ShowroomParis} onClick={handleCloseFullScreenParis} alt="Showroom de Maison du Revêtement à Paris"/>
                                <div onClick={handleCloseFullScreenParis} className="showroom_bloc_deux_fullscreen_croixFullscreen"><i className="fa-solid fa-xmark fa-2xl"></i></div>
                            </div>
                        :
                            <div className="showroom_bloc_deux">
                                <img src={ShowroomParis} onClick={handleImageClickParis} alt="Showroom de Maison du Revêtement à Paris"/>
                                <div className="showroom_bloc_deux_texte">
                                    <h3><strong>Showroom Paris 17</strong></h3>
                                    <a href="https://www.google.com/maps/place/Maison+du+Rev%C3%AAtement+%7C+Paris+17/@48.8819998,2.2938824,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66f131a15cf87:0x7ea70892d2e6ac07!8m2!3d48.8819963!4d2.2964573!16s%2Fg%2F11kqtpd7qp?entry=ttu"><h4 className="showroom_adresse">58 rue Pierre Demours<br />75017 PARIS</h4></a>
                                    <a href="tel:+33182830071">01.82.83.00.71</a>
                                    <a href="mailto:paris17@maisondurevetement.com">paris17@maisondurevetement.com</a>
                                    <p>Du Mardi au Samedi</p>
                                    <p>De 10:00 à 12:30 et de 14:00 à 19:00</p>
                                </div>
                            </div>
                        }
                        { showFullScreenVG ?
                            <div className="showroom_bloc_deux_fullscreen">
                                <img src={ShowroomVilleneuve} onClick={handleCloseFullScreenVG} alt="Showroom de Maison du Revêtement à Villeneuve-la-Garenne"/>
                                <div onClick={handleCloseFullScreenVG} className="showroom_bloc_deux_fullscreen_croixFullscreen"><i className="fa-solid fa-xmark fa-2xl"></i></div>
                            </div>
                        :
                            <div className="showroom_bloc_deux">
                                <img src={ShowroomVilleneuve} onClick={handleImageClickVG} alt="Showroom de Maison du Revêtement à Villeneuve-la-Garenne"/>
                                <div className="showroom_bloc_deux_texte">
                                    <h3><strong>Entrepôt Porte de Paris</strong></h3>
                                    <a href="https://www.google.com/maps/place/Maison+du+Rev%C3%AAtement,+23+Av.+du+Chemin+des+Reniers,+92390+Villeneuve-la-Garenne/@48.9326753,2.3242003,17z/data=!4m6!3m5!1s0x47e66fe73242f781:0xfce1191e0496d851!8m2!3d48.9326753!4d2.3242003!16s%2Fg%2F11s7qzn61f"><h4 className="showroom_adresse">23 Avenue du Chemin des Reniers<br />92390 VILLENEUVE-LA-GARENNE</h4></a>
                                    <a href="tel:+33156555526">01.56.55.55.26</a>
                                    <a href="mailto:support@maisondurevetement.com">support@maisondurevetement.com</a>
                                    <p>Du Lundi au Vendredi</p>
                                    <p>De 10:00 à 18:00</p>
                                </div>
                            </div>
                        }
                    </div>
                </section>
                <section className="presentation">
                    <div className="presentation_photo">
                        <img src={SectionPresentation} alt="Lavabo et mirroir de salle de bain en carrelage"/>
                    </div>
                    <div className="presentation_bloc">
                        <div className="presentation_bloc_titre">
                            <h2>Qui sommes-nous ?</h2>
                            <span className="presentation_bloc_titre_ligne"></span>
                        </div>
                        <div className="presentation_bloc_texte">
                            <div>
                                <h1>Maison du Revêtement</h1>
                                <p>est une entreprise spécialisée dans la vente de revêtement de murs et de sols, dont l'entrepôt se situe à Villeneuve-la-Garenne en Ile-de-France, et dispose d'un showroom parisien dans le 17ème arrondissement. </p>
                            </div>
                            <p>Passionnés par la décoration et le design depuis toujours, notre mission est de mettre notre expertise à votre disposition, de vous conseiller tout en vous accompagnant tout au long de votre projet de décoration et/ou de rénovation.</p>
                            <p>La force de Maison du revêtement est de proposer des produits haut de gamme à un tarif accessible à tous. Notre large choix de coloris et de matière répondra parfaitement à vos demandes les plus spécifiques, pour enfin créer "votre intérieur", un espace de vie à "votre image".</p>
                        </div>
                    </div>
                </section>
                <section className="accompagnement">
                    <div className="accompagnement_bloc">
                        <div className="accompagnement_bloc_titre">
                            <h2>Des passionnés pour vous accompagner !</h2>
                            <span className="accompagnement_bloc_titre_ligne"></span>
                        </div>
                        <div className="accompagnement_bloc_texte">
                            <p>Chez Maison du Revêtement, nous savons que chaque aménagement de votre domicile est un projet d'envergure.</p>
                            <p>Notre souhait : vous présenter les plus belles pièces qui existent, en adéquation avec l'atmosphère que vous recherchez.</p>
                            <p>Parce que notre priorité reste la qualité, nos produits proviennent directement d'usines françaises, italiennes et espagnoles.</p>
                            <p>Sachez que nous sommes à votre disposition autant en showroom qu'en nous sollicitant via notre formulaire de contact.</p>
                        </div>
                        <div className="boutons">
                            <button aria-label="Prendre Rendez-vous" className="bouton_accompagnement"><a aria-label="Demande d'Accompagnement" href="https://maisondurevetement.odoo.com/contact">PRENDRE RENDEZ-VOUS</a></button>
                        </div>
                    </div>
                    <div className="accompagnement_photo">
                        <img src={SectionAccompagnement} alt="Salon avec parquet contrecollé"/>
                    </div>
                </section>
                <section className="pro">
                    <div className="pro_photo">
                        <img src={SectionPro} alt="Salon avec parquet massif"/>
                    </div>
                    <div className="pro_bloc">
                        <div className="pro_bloc_titre">
                            <h2>Pour vous, professionnels</h2>
                            <span className="pro_bloc_titre_ligne"></span>
                        </div>
                        <div className="pro_bloc_texte">
                            <p>Vous êtes architectes ou artisans ? Nous vous proposons un service sur-mesure afin de réaliser tous vos projets.</p>
                            <p>Contactez-nous directement pour découvrir nos tarifs pro ainsi que notre service d'envoi d'échantillons et de catalogues... nous répondrons forcément à vos attentes !</p>
                            <p>Demandez également la création de votre espace pro afin de pouvoir conserver le suivi de vos devis, bons de commande et factures.</p>
                        </div>
                        <div className="boutons">
                            <button aria-label="Nous contacter" className="bouton_pro"><a aria-label="Nous contacter" href="https://maisondurevetement.odoo.com/contact">NOUS CONTACTER</a></button>
                            {/* <button aria-label="Accéder à mon espace pro" className="bouton_pro"><a aria-label="Accéder à mon espace pro" href="https://maisondurevetement.odoo.com/web/login">ESPACE PRO</a></button> */}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}