/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import Footer from '../../composants/Footer'
import Test from "../../assets_opti/produits/drapeauFrance_opti.webp"

/* Dépendances */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'

/* Images */
import Visa from '../../assets/visa.png'
import MC from '../../assets/masterCard.png'
import AE from '../../assets/americanExpress.png'
import VirementBancaire from '../../assets/virementBancaire.png'

/* Code */
export default function Panier() {

    const emptyItems = false;

    return (
        <>
            <header className={`header header_panier`}>
                <Header />
                <TitreGammes titre="Mon Panier" />
            </header>
            <main className="panierMain">
                <div className="panierAriane">

                </div>
                <section className="panier">
                    <div className="panier_gauche">
                        <div className="panier_gauche_titre">
                            <p>MES ARTICLES</p>
                        </div>
                        <div className="panier_gauche_corps">
                            { emptyItems === true ?
                                <p>Votre panier est vide</p>
                            :
                                <>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panier_gauche_corps_card">
                                        <img src={Test} className="panier_gauche_corps_card_photo" alt=""></img>
                                        <div className="panier_gauche_corps_card_texte">
                                            <div className="panier_gauche_corps_card_texte_haut">
                                                <div className="panier_gauche_corps_card_texte_haut_gauche">
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_nom">NYX CENERE Natural 60x120</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_gauche_tarif">40,00 €HT /m²</p>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_haut_droite">
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ht">400,00 €HT</p>
                                                    <p className="panier_gauche_corps_card_texte_haut_droite_ttc">soit 500,00 €TTC</p>
                                                </div>
                                            </div>
                                            <div className="panier_gauche_corps_card_texte_bas">
                                                <div className="panier_gauche_corps_card_texte_bas_boutonUn">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonUn_label">Surface en m² :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonUn_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_boutonDeux">
                                                    <label htmlFor="boutonMetrage" className="panier_gauche_corps_card_texte_bas_boutonDeux_label">Nombre de boîtes :</label>
                                                    <div className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur">
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonMoins" aria-label="enlever un au compteur">
                                                            <FontAwesomeIcon icon={faMinus} size="l" />
                                                        </button>
                                                        <button aria-label="nombre de mètres-carré">
                                                            <input name="boutonMetrage" type="text" min="1" max="999" step="0.01" value="1.44" aria-label="nombre de mètres-carré"></input>
                                                        </button>
                                                        <button className="panier_gauche_corps_card_texte_bas_boutonDeux_conteneur_boutonPlus" aria-label="ajouter un au compteur">
                                                            <FontAwesomeIcon icon={faPlus} size="l" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="panier_gauche_corps_card_texte_bas_corbeille">
                                                    <FontAwesomeIcon icon={faTrashCan} size="l" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            
                        </div>
                        <div className="panier_gauche_boutons">
                            <div className="panier_gauche_boutons_continuer">
                                <i class="fa-solid fa-caret-left"></i>
                                <p>CONTINUER MES ACHATS</p>
                            </div>
                            <div className="panier_gauche_boutons_valider panier_valider">
                                <p>VALIDER MA COMMANDE</p>
                            </div>
                        </div>
                    </div>
                    <div className="panier_droite">
                        <div className="panier_droite_titre">
                            <p>RÉCAPITULATIF</p>
                        </div>
                        <div className="panier_droite_corps">
                            <div className="panier_droite_corps_montants">
                                <div className="panier_droite_corps_montants_livraison">
                                    <p>Livraison</p>
                                    <p>à déterminer plus tard</p>
                                </div>
                                <div className="panier_droite_corps_montants_separateur"></div>
                                <div>
                                    <p>TOTAL HT</p>
                                    <p>XXX €</p>
                                </div>
                                <div>
                                    <p>TVA</p>
                                    <p>XXX €</p>
                                </div>
                                <div className="panier_droite_corps_montants_separateur"></div>
                                <div className="panier_droite_corps_montants_ttc">
                                    <p>TOTAL TTC</p>
                                    <p>XXX €</p>
                                </div>
                                <div className="panier_droite_corps_montants_valider panier_valider">
                                    <p>VALIDER MA COMMANDE</p>
                                </div>
                            </div>
                            <div className="panier_droite_corps_paiements">
                                <div className="panier_droite_corps_paiements_titre">
                                    <p>MODES DE PAIEMENT DISPONIBLES</p>
                                </div>
                                <div className="panier_droite_corps_paiements_modes">
                                    <div>
                                        <img src={Visa} alt="Logo des cartes VISA"></img>
                                    </div>
                                    <div>
                                        <img src={MC} alt="Logo des cartes MASTERCARD"></img>
                                    </div>
                                    <div>
                                        <img src={AE} alt="Logo des cartes AMERICAN EXPRESS"></img>
                                    </div>
                                    <div>
                                        <img src={VirementBancaire} alt="Logo des virements bancaires"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="panier_droite_corps_engagements">
                                <div className="panier_droite_corps_engagements_titre">
                                    <p>NOS ENGAGEMENTS</p>
                                </div>
                                <div className="panier_droite_corps_engagements_livraison">
                                    <span><i className="fa-solid fa-truck-fast fa-xl"></i></span>
                                    <p>Livraison partout en France</p>
                                </div>
                                <div className="panier_droite_corps_engagements_stockage">
                                    <span><i className="fa-solid fa-dolly fa-xl"></i></span>
                                    <p>Stockage gratuit jusqu'à 3 mois</p>
                                </div>
                                <div className="panier_droite_corps_engagements_sav">
                                    <span><i class="fa-solid fa-people-arrows fa-xl"></i></span>
                                    <p>Service Après-Vente réactif</p>
                                </div>
                            </div>
                            <div className="panier_droite_corps_question">
                                <p>Une question concernant un article ?</p>
                                <p>On vous répond au 01.82.83.00.71</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}