/* Modules */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

/* Pages */
import Home from './pages/Home'
import Gammes from './pages/main/Gammes'
import Produits from './pages/main/Produits'
import Article from './pages/main/Article'
import Destockage from './pages/autre/Destockage'
import Promo from './pages/autre/Promo'
import Recherche from './pages/autre/Recherche'
import Livraison from './pages/autre/Livraison'
import CGV from './pages/autre/CGV'
import MentionsLegales from './pages/autre/MentionsLegales'
import EspaceClient from './pages/autre/EspaceClient'
import Erreur from './pages/autre/Erreur'
import Panier from './pages/main/Panier'

/* Composants */
// import BanniereTop from './composants/BanniereTop'

/* Fonctions */
import ScrollToTop from './fonctions/ScrollToTop'

/* Styles */
import './styles/home.css'
import './styles/pages/gammes/Gammes.css'
import './styles/pages/produits/Produits.css'
import './styles/pages/articles/Article.css'
import './styles/autre/Header&Footer.css'
import './styles/autre/Autre.css'
import './styles/utils/Utils.css'
import './styles/pages/panier/Panier.css'

/* Les "items" sont conservés actuellement 30 minutes dans le localStorage */
var min = 30;

var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');

if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now - setupTime > min * 60 * 1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);
    }
}

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
  // <BanniereTop />

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />

          <Route path="/:gamme" element={<Gammes />} />
          <Route path="/:gamme/:produit" element={<Produits />} />
          <Route path="/:gamme/:produit/:sousCategorie" element={<Produits />} />
          <Route path="/:gamme/:produit/:sousCategorie/:stock" element={<Produits />} />
          <Route path="/:gamme/:produit/:sousCategorie/:stock/:article" element={<Article />} />

          <Route path="/en-stock/:gammeStock" element={<Destockage />}/>
          <Route path="/promo/:gammePromo" element={<Promo />}/>
          <Route path="/recherche/:recherche" element={<Recherche />}/>

          <Route path="/mon-panier" element={<Panier />}/>
          <Route path="/livraison-et-retour" element={<Livraison />}/>
          <Route path="/conditions-generales-de-vente" element={<CGV />}/>
          <Route path="/mentions-legales" element={<MentionsLegales />}/>
          <Route path="/espace-client" element={<EspaceClient />}/>

          <Route path="*" element={<Erreur texte={"Cette page semble ne pas exister !"} />} />
        </Route>
      </Routes>
    </BrowserRouter>
)

