/* Modules */
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom"

/* Code */
export default function FilDAriane() {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();
    let gamme = UrlParams.gamme;
    let produit = UrlParams.produit;
    let sousCategorie = UrlParams.sousCategorie;
    let stock = UrlParams.stock;

    return (
        <nav className="ariane">
            {  sousCategorie !== undefined && sousCategorie !== "pas-de-sous-categorie" ?
                <ul>
                    <li key={gamme}><Link to={"/" + gamme}>{gamme.toUpperCase()}</Link></li>
                    <li key={produit}><Link to={"/" + gamme + "/" + produit + "/pas-de-sous-categorie/" + stock}>{produit.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")}</Link></li>
                    <li key={sousCategorie}>{sousCategorie.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")}</li>
                </ul>
            :
                <ul>
                    <li key={gamme}><Link to={"/" + gamme}>{gamme.toUpperCase()}</Link></li>
                    <li key={produit}>{produit.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")}</li>
                </ul>
            } 
        </nav>
    )
}