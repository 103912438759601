/* Modules */
import { Link } from 'react-router-dom'

/* Images */
import CarrelageMain from "../assets_opti/home/home-carrelage-main_opti.webp"
import CarrelageSolMurs from "../assets_opti/home/home-carrelage-sol-murs_opti.webp"
import CarrelageFaience from "../assets_opti/home/home-carrelage-faiences_opti.webp"
import CarrelageExterieur from "../assets_opti/home/home-carrelage-exterieurs_opti.webp"
import ParquetMain from "../assets_opti/home/home-parquet-main_opti.webp"
import ParquetMassif from "../assets_opti/home/home-parquet-massif_opti.webp"
import ParquetContrecolle from "../assets_opti/home/home-parquet-contrecolle_opti.webp"
import ParquetPVC from "../assets_opti/home/home-parquet-pvc_opti.webp"
import TendanceMarbre from "../assets_opti/home/home-tendance-marbre_opti.webp"
import TendanceTravertin from "../assets_opti/home/home-tendance-travertin_opti.webp"
import TendanceTerrazzo from "../assets_opti/home/home-tendance-terrazzo_opti.webp"
import TendanceZellige from "../assets_opti/home/home-tendance-zellige_opti.webp"

/* Code */
export default function Gallery() {
    return (
        <section className="gallery">
            <div className="gallery_titre_main">
                <h2>NOS GAMMES DE PRODUITS</h2>
                <h3>pour les professionnels et les particuliers</h3>
            </div>
            <div className="gallery_un gallery_car">
                <div className="gallery_deux">
                    <Link to="/carrelage">
                        <img src={CarrelageMain} alt="Carrelage de Maison du Revêtement"/>
                        <h4 className="gallery_un_titre">Carrelage</h4>
                    </Link>
                </div>
                <div className="gallery_deux">
                    <div className="gallery_trois">
                        <Link to="/carrelage/sols-&-murs/pas-de-sous-categorie/stock-et-commande">
                            <img src={CarrelageSolMurs} alt="Carrelage Sols & Murs de Maison du Revêtement"/>
                            <h4>Sols & Murs</h4>
                        </Link>
                    </div>
                    <div className="gallery_trois">                        
                        <div className="gallery_quatre">
                            <Link to="/carrelage/les-faïences-murales/pas-de-sous-categorie/stock-et-commande">
                                <img src={CarrelageFaience} alt="Carrelage en Faïences de Maison du Revêtement"/>
                                <h4>Les Faïences<br /> Murales</h4>
                            </Link>
                        </div>
                        <div className="gallery_quatre">
                            <Link to="/carrelage/les-extérieurs/pas-de-sous-categorie/stock-et-commande">
                                <img src={CarrelageExterieur} alt="Carrelage en Extérieur de Maison du Revêtement"/>
                                <h4>Les<br />Extérieurs</h4>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery_un gallery_par">
                <div className="gallery_deux">
                    <div className="gallery_trois">
                        <Link to="/parquet/massif/pas-de-sous-categorie/stock-et-commande">
                            <img src={ParquetMassif} alt="Parquet Massif de Maison du Revêtement"/>
                            <h4>Massif</h4>
                        </Link>
                    </div>
                    <div className="gallery_trois">                        
                        <div className="gallery_quatre">
                            <Link to="/parquet/contrecollé/pas-de-sous-categorie/stock-et-commande">
                                <img src={ParquetContrecolle} alt="Parquet Contrecollé de Maison du Revêtement"/>
                                <h4>Contrecollé</h4>
                            </Link>
                        </div>
                        <div className="gallery_quatre">
                            <Link to="/parquet/stratifié-&-pvc/pas-de-sous-categorie/stock-et-commande">
                                <img src={ParquetPVC} alt="Parquet en PVC de Maison du Revêtement"/>
                                <h4>PVC</h4>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="gallery_deux">
                    <Link to="/parquet">
                        <img src={ParquetMain} alt="Parquet de Maison du Revêtement"/>
                        <h4 className="gallery_un_titre">Parquet</h4>
                    </Link>
                </div>
            </div>
            <div className="gallery_titre_ten">
                <h2>LES TENDANCES DU MOMENT</h2>
            </div>
            <div className="gallery_ten">                        
                <div className="gallery_six">
                    <Link to="/carrelage/sols-&-murs/imitation-marbre/stock-et-commande">
                        <img src={TendanceMarbre} alt="Les carrelages Imitation Marbre de Maison du Revêtement"/>
                        <h4>Effet<br />Marbre</h4>
                    </Link>
                </div>
                <div className="gallery_sept">
                    <div className="gallery_huit">
                        <Link to="/carrelage/sols-&-murs/imitation-travertin/stock-et-commande">
                            <img src={TendanceTravertin} alt="Les carrelages Imitation Travertin de Maison du Revêtement"/>
                            <h4>Imitation<br />Travertin</h4>
                        </Link>
                    </div>
                    <div className="gallery_huit">
                        <div className="gallery_neuf">
                            <Link to="/carrelage/les-faïences-murales/imitation-zellige/stock-et-commande">
                                <img src={TendanceZellige} alt="Les faïences Imitation Zellige de Maison du Revêtement"/>
                                <h4>Faïence<br />Zellige</h4>
                            </Link>                            
                        </div>
                        <div className="gallery_neuf">
                            <Link to="/carrelage/les-terrazzos/pas-de-sous-categorie/stock-et-commande">
                                <img src={TendanceTerrazzo} alt="Les carrelages en vrai Terrazzo de Maison du Revêtement"/>
                                <h4>Les<br />Terrazzos</h4>
                            </Link>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}