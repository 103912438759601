/* Modules */
import React from "react"
import { Link } from 'react-router-dom'

/* Code */
export default function NavigationArticle(props) {
    let gamme = props.gamme;
    let produit = props.produit;
    let sousCategorie = props.sousCategorie;
    let oneCard = props.oneCard;
    let cat = props.cat;
    let trimedCat = cat.map(element => {
        return element.trim();
    }); 

    return (
        <section className="navigationArticle">
            { trimedCat && trimedCat.length === 3 ?
                <ul>
                    <li><Link to={"/" + gamme}>{trimedCat[0].toUpperCase()}</Link></li>
                    <li><Link to={"/" + gamme + "/" + produit + "/pas-de-sous-categorie/stock-et-commande"}>{trimedCat[1]}</Link></li>
                    <li><Link to={"/" + gamme + "/" + produit + "/" + sousCategorie + "/stock-et-commande"}>{trimedCat[2]}</Link></li>
                    <li className="navigationArticle_nomArticle"><span>{oneCard.barcode}</span></li>
                </ul>
            :
                <ul>
                    <li><Link to={"/" + gamme}>{trimedCat[0].toUpperCase()}</Link></li>
                    <li><Link to={"/" + gamme + "/" + produit + "/pas-de-sous-categorie/stock-et-commande"}>{trimedCat[1]}</Link></li>
                    <li className="navigationArticle_nomArticle"><span>{oneCard.barcode}</span></li>
                </ul>
            }
        </section>
    )
}