/* Modules */
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

/* Dépendences */
import { productsService } from "../../_services/products.service"

/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import Navigation from "../../composants/Navigation"
import Aide from "../../composants/Aide"
import CardArticle from "../../composants/CardArticle"
import AfficherPlusDeProduits from "../../composants/AfficherPlusDeProduits"
import BoutonScrollToTop from "../../composants/BoutonScrollToTop"
import Erreur from "../autre/Erreur"
import Footer from "../../composants/Footer"

/* Code */
export default function Produits() {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();
    let gamme = UrlParams.gamme;
    let produit = UrlParams.produit;
    let sousCategorie = UrlParams.sousCategorie;
    let stock = UrlParams.stock;

    let [cat, setCat] = useState({});
    let [sousCat, setSousCat] = useState([]);
    let [sousCatId, setSousCatId] = useState(0);
    let [subCat, setSubCat] = useState([]);
    let [subCatId, setSubCatId] = useState(null);
    let [nbProduits, setNbProduits] = useState(32);
    let [selectedSize, setSelectedSize] = useState(null);
    let [cards, setCards] = useState([]);
    let [nbTotal, setNbTotal] = useState(null);
    let [pageIsLoading, setPageIsLoading] = useState(true);
    let [elementIsLoading, setElementIsLoading] = useState(false);

    /* Récupération des catégories en question */
    function getAllCatsBySousCat(){
        productsService.getAllCats()
        .then((res) => {
            setNbProduits(32);
            setCat(res.data.find(cat => gamme === cat.name.toLowerCase()));
            setSousCat((res.data.find(cat => gamme === cat.name.toLowerCase())).childs.find(sousCat => produit === sousCat.name.replaceAll(" ", "-").toLowerCase()));
            setSousCatId((res.data.find(cat => gamme === cat.name.toLowerCase())).childs.find(sousCat => produit === sousCat.name.replaceAll(" ", "-").toLowerCase()).id);
            setSubCat("pas-de-sous-categorie");
            setSubCatId(0);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (sousCategorie === undefined || sousCategorie === "pas-de-sous-categorie") {
            getAllCatsBySousCat();
        }
    }, [produit, sousCategorie]);

    /* Affichage des cartes de la catégorie choisie */
    function getAllCardsBySousCat(){
        setElementIsLoading(true);
        productsService.getAllCards({
            limit : nbProduits,
            categId : sousCatId,
            available : stock === "en-stock" ? true : null,
            size: selectedSize
        })
        .then((res) => {
            setCards(res.data.products);
            setNbTotal(res.data.total);
            setPageIsLoading(false);
            setElementIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if(sousCatId !== 0) {
            getAllCardsBySousCat();
        }
    }, [sousCatId, nbProduits, stock, selectedSize]);

    /* Récupération des sous-catégories en question */
    function getAllCatsBySubCat(){
        productsService.getAllCats()
        .then((res) => {
            setNbProduits(32);
            setCat(res.data.find(cat => gamme === cat.name.toLowerCase()));
            setSousCat((res.data.find(cat => gamme === cat.name.toLowerCase())).childs.find(sousCat => produit === sousCat.name.replaceAll(" ", "-").toLowerCase()));
            setSousCatId(0);
            setSubCat((res.data.find(cat => gamme === cat.name.toLowerCase())).childs.find(sousCat => produit === sousCat.name.replaceAll(" ", "-").toLowerCase()).childs.find(subCat => sousCategorie === subCat.name.toLowerCase().replaceAll(" ", "-")));
            setSubCatId((res.data.find(cat => gamme === cat.name.toLowerCase())).childs.find(sousCat => produit === sousCat.name.replaceAll(" ", "-").toLowerCase()).childs.find(subCat => sousCategorie === subCat.name.toLowerCase().replaceAll(" ", "-")).id);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        if(sousCategorie !== undefined && sousCategorie !== "pas-de-sous-categorie"){
            getAllCatsBySubCat();
        }        
    }, [sousCategorie]);

    /* Affichage des cartes de la sous-catégorie choisie */
    function getAllCardsBySubCat(){
        setElementIsLoading(true);
        productsService.getAllCards({
            limit : nbProduits,
            categId : subCatId,
            available : stock === "en-stock" ? true : null,
            size: selectedSize
        })
        .then((res) => {
            setCards(res.data.products);
            setNbTotal(res.data.total);
            setPageIsLoading(false);
            setElementIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    useEffect(() => {
        if(subCatId !== null && subCatId !== 0 && sousCategorie !== "pas-de-sous-categorie") {
            getAllCardsBySubCat();
        }
    }, [subCatId, nbProduits, stock, selectedSize]);

    /* Remontées d'infos aux composants parents */
    const callbackNbProduits = (nbProduits) => {
        setNbProduits(nbProduits);
    }

    const callbackSelectedSize = (selectedSize) => {
        setSelectedSize(selectedSize);
    }

    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = `${gamme.charAt(0).toUpperCase() + gamme.slice(1)} ${produit.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")} | Maison du Revêtement`;
        document.getElementsByTagName("meta")[2].content= `Consulter les articles de la catégorie ${gamme.charAt(0).toUpperCase() + gamme.slice(1)} ${produit.split("-").map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")} sélectionnés par Maison du Revêtement, avec leur tarif notamment.`;
    }, [gamme, produit]);

    return (
        <>
            { sousCat !== undefined && subCat !== undefined ?
                <>
                    { pageIsLoading ?
                        <div className="page-spinner">
                            <div className="page-spinner_interieur">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    :
                        <>
                            <header className={`header header_produits_${gamme}`}>
                                <Header />
                                { sousCategorie !== "pas-de-sous-categorie" ?
                                    <TitreGammes titre={`${cat.name} ${sousCat.name} ${subCat.name} ${stock === "en-stock" ? "en Stock" : ""}`} />
                                :
                                    <TitreGammes titre={`${cat.name} ${sousCat.name} ${stock === "en-stock" ? "en Stock" : ""}`} />
                                }
                            </header>
                            <main className="produitsMain">
                                <Navigation onClick={callbackSelectedSize} cat={cat} sousCat={sousCat} subCat={subCat} selectedSize={selectedSize} elementIsLoading={elementIsLoading} />
                                <CardArticle cards={cards} nbTotal={nbTotal}/>
                                <AfficherPlusDeProduits onClick={callbackNbProduits} nbProduits={nbProduits} nbTotal={nbTotal} elementIsLoading={elementIsLoading} />
                                <Aide catName={cat.name} />
                            </main>
                            <Footer />
                            <BoutonScrollToTop />
                        </>
                    }
                </>
            :
                <Erreur texte={"Cette sous-catégorie d'articles semble ne pas exister !"} />
            }
        </>
    )
}