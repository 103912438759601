/* Modules */
import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'

/* Images */
import DrapeauFrance from "../assets_opti/produits/drapeauFrance_opti.webp"
import DrapeauEspagne from "../assets_opti/produits/drapeauEspagne_opti.webp"
import DrapeauItalie from "../assets_opti/produits/drapeauItalie_opti.webp"

/* Code */
export default function MisEnAvant(props) {
    let promoCarouselCards = props.promoCarouselCards;
    let stockCarouselCards = props.stockCarouselCards;

    /* Section Stock/Populaire - basculement d'états */
    const [isActive, setIsActive] = useState(false);
    let a = 0;
    let b = 0;
    let [limitPromo, setLimitPromo] = useState(0);

    let x = 0;
    let y = 0;
    let [limitStock, setLimitStock] = useState(0);

    const handleClick = event => {
        setIsActive(current => !current);
        document.getElementById("misEnAvant_bloc_article_promo").style.transform=`translateX(0px)`;
        document.getElementById("fleche_gauche_promo").style.color="#FAFAFA";
        document.getElementById("fleche_gauche_promo").style.cursor="default";
        document.getElementById("fleche_droite_promo").style.color="#28292A";
        document.getElementById("fleche_droite_promo").style.cursor="pointer";
        document.getElementById("misEnAvant_bloc_article_stock").style.transform=`translateX(0px)`;
        document.getElementById("fleche_gauche_stock").style.color="#FAFAFA";
        document.getElementById("fleche_gauche_stock").style.cursor="default";
        document.getElementById("fleche_droite_stock").style.color="#28292A";
        document.getElementById("fleche_droite_stock").style.cursor="pointer";
    };

    /* Carousel de la section Promo */
    useEffect(() => {
        if(promoCarouselCards) {
            setLimitPromo(promoCarouselCards.length - 6);
        }
    }, [promoCarouselCards]);

    const photoSuivantePromo = () => {
        document.getElementById("fleche_gauche_promo").style.color="#28292A";
        document.getElementById("fleche_gauche_promo").style.cursor="pointer";

        if(a < limitPromo) {
            a++;
            b = a * -320;
            document.getElementById("misEnAvant_bloc_article_promo").style.transform=`translateX(${b}px)`;
            document.getElementById("misEnAvant_bloc_article_promo").style.transition="all 0.2s";
        }

        if(a === limitPromo) {
            document.getElementById("fleche_droite_promo").style.color="#FAFAFA";
            document.getElementById("fleche_droite_promo").style.cursor="default";
        }
    }

    const photoPrecedentePromo = () => {
        document.getElementById("fleche_droite_promo").style.color="#28292A";
        document.getElementById("fleche_droite_promo").style.cursor="pointer";

        if(a > 0) {
            a--;
            b = a * -320;
            document.getElementById("misEnAvant_bloc_article_promo").style.transform=`translateX(${b}px)`;
            document.getElementById("misEnAvant_bloc_article_promo").style.transition="all 0.2s";
        }

        if(a === 0) {
            document.getElementById("fleche_gauche_promo").style.color="#FAFAFA";
            document.getElementById("fleche_gauche_promo").style.cursor="default";
        }
    }

    /* Carousel de la section Stock */
    useEffect(() => {
        if(stockCarouselCards) {
            setLimitStock(stockCarouselCards.length - 6);
        }
    }, [stockCarouselCards]);

    const photoSuivanteStock = () => {
        document.getElementById("fleche_gauche_stock").style.color="#28292A";
        document.getElementById("fleche_gauche_stock").style.cursor="pointer";

        if(x < limitStock) {
            x++;
            y = x * -320;
            document.getElementById("misEnAvant_bloc_article_stock").style.transform=`translateX(${y}px)`;
            document.getElementById("misEnAvant_bloc_article_stock").style.transition="all 0.2s";
        }

        if(x === limitStock) {
            document.getElementById("fleche_droite_stock").style.color="#FAFAFA";
            document.getElementById("fleche_droite_stock").style.cursor="default";
        }
    }

    const photoPrecedenteStock = () => {
        document.getElementById("fleche_droite_stock").style.color="#28292A";
        document.getElementById("fleche_droite_stock").style.cursor="pointer";

        if(x > 0) {
            x--;
            y = x * -320;
            document.getElementById("misEnAvant_bloc_article_stock").style.transform=`translateX(${y}px)`;
            document.getElementById("misEnAvant_bloc_article_stock").style.transition="all 0.2s";
        }

        if(x === 0) {
            document.getElementById("fleche_gauche_stock").style.color="#FAFAFA";
            document.getElementById("fleche_gauche_stock").style.cursor="default";
        }
    }

    return (
        <section className="misEnAvant">
            <div className="misEnAvant_titre">
                <div className="misEnAvant_titre_prix">
                    <button className={isActive ? "" : "titre-actif"} onClick={handleClick}><h3>PROMOTIONS DU MOMENT</h3></button>
                    <span className="misEnAvant_titre_ligne"></span>
                </div>
                <div className="misEnAvant_titre_top">
                    <button className={isActive ? "titre-actif" : ""} onClick={handleClick}><h3>DISPONIBLES EN STOCK</h3></button>
                    <span className="misEnAvant_titre_ligne"></span>
                </div>
            </div>
            <div className={`misEnAvant_bloc ${isActive ? "" : "show-bloc"}`}>
                <div className="misEnAvant_bloc_main">
                    <div onClick={photoPrecedentePromo} className="misEnAvant_bloc_fleche" id="fleche_gauche_promo"><i className="fa-solid fa-chevron-left fa-2xl"></i></div>
                    <div className="misEnAvant_bloc_fige">
                        <div className="misEnAvant_bloc_article" id="misEnAvant_bloc_article_promo">
                            { promoCarouselCards && promoCarouselCards.map(card => {
                                return (
                                    <div className="misEnAvant_bloc_article_prix" key={card.barcode}>
                                        <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="misEnAvant_bloc_article_photo">
                                            <img className="misEnAvant_bloc_article_photo_un" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode + " mis en avant par Maison du Revêtement"}/>
                                            { card.additional_photo !== false ?
                                                <img className="misEnAvant_bloc_article_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            :
                                                <img className="misEnAvant_bloc_article_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            }
                                            { card.quantityInStock >= 10 || card.stock === true ?
                                                <div className="bulleStock">en stock</div>
                                            :
                                                null
                                            }
                                            { card.discount !== 0 ?
                                                <div className="bullePromo">- {card.discount} %</div>     
                                            :
                                                <div className="bullePromo">PRIX USINE</div>
                                            }
                                            { card.provenance === "F" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauFrance} alt="drapeau de la France"/>
                                                </div>        
                                            :
                                                null
                                            }
                                            { card.provenance === "E" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauEspagne} alt="drapeau de l'Espagne"/>
                                                </div>        
                                            :
                                                null
                                            }
                                            { card.provenance === "I" ?
                                                <div className="drapeau">
                                                    <img src={DrapeauItalie} alt="drapeau de l'Italie"/>
                                                </div>        
                                            :
                                                null
                                            }
                                        </Link>
                                        <div className="misEnAvant_bloc_article_info">
                                            <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="misEnAvant_bloc_article_info_nom">
                                                <p>{card.barcode}</p>
                                            </Link>
                                            <p className="misEnAvant_bloc_article_info_cat">
                                                {card.category.display_name.split(" / ")[1] + " / " + card.category.display_name.split(" / ")[2]}
                                            </p>
                                            <div className="misEnAvant_bloc_article_info_prix">
                                                <p className="misEnAvant_bloc_article_info_prix_gauche_offre">
                                                    {(card.price_without_taxes * (1 - (card.discount / 100))).toFixed(2) + " €HT /m²"}
                                                </p>
                                                <p className="misEnAvant_bloc_article_info_prix_droite_offre">
                                                    {"(" + ((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2) + " €TTC /m²)"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div onClick={photoSuivantePromo} className="misEnAvant_bloc_fleche" id="fleche_droite_promo"><i className="fa-solid fa-chevron-right fa-2xl"></i></div>
                </div>
                <div className="misEnAvant_bloc_bouton">
                    <div className="misEnAvant_bloc_bouton_un">
                        <Link to="/promo/carrelage-et-parquet" key="promo">TOUS NOS ARTICLES EN PROMO</Link>
                    </div>
                </div>
            </div>
            <div className={`misEnAvant_bloc ${isActive ? "show-bloc" : ""}`}>
                <div className="misEnAvant_bloc_main">
                    <div onClick={photoPrecedenteStock} className="misEnAvant_bloc_fleche" id="fleche_gauche_stock"><i className="fa-solid fa-chevron-left fa-2xl"></i></div>
                    <div className="misEnAvant_bloc_fige">
                    <div className="misEnAvant_bloc_article" id="misEnAvant_bloc_article_stock">
                            { stockCarouselCards && stockCarouselCards.map(card => {
                                return (
                                    <div className="misEnAvant_bloc_article_prix" key={card.barcode}>
                                        <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="misEnAvant_bloc_article_photo">
                                            <img src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode + " mis en avant par Maison du Revêtement"}/>
                                            { card.additional_photo !== false ?
                                                <img className="misEnAvant_bloc_article_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            :
                                                <img className="misEnAvant_bloc_article_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                            }
                                            <div className="bulleStock">en stock</div>
                                        </Link>
                                        <div className="misEnAvant_bloc_article_info">
                                            <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="misEnAvant_bloc_article_info_nom">
                                                <p>{card.barcode}</p>
                                            </Link>
                                            <p className="misEnAvant_bloc_article_info_cat">
                                                {card.category.display_name.split(" / ")[1] + " / " + card.category.display_name.split(" / ")[2]}
                                            </p>
                                            <div className="misEnAvant_bloc_article_info_prix">
                                                <p className="misEnAvant_bloc_article_info_prix_gauche">
                                                    {(card.price_without_taxes * (1 - (card.discount / 100))).toFixed(2) + " €HT /m²"}
                                                </p>
                                                <p className="misEnAvant_bloc_article_info_prix_droite">
                                                    {"(" + ((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2) + " €TTC /m²)"}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div onClick={photoSuivanteStock} className="misEnAvant_bloc_fleche" id="fleche_droite_stock"><i className="fa-solid fa-chevron-right fa-2xl"></i></div>
                </div>
                <div className="misEnAvant_bloc_bouton">
                    <div className="misEnAvant_bloc_bouton_un">
                        <Link to="/en-stock/carrelage-et-parquet" key="stock">TOUS NOS ARTICLES EN STOCK</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}