/* Modules */
import React from "react"
import { Link } from 'react-router-dom'

/* Images */
import PetitLogo from "../assets_opti/logo/V2CB_opti.webp";
import { productsService } from "../_services/products.service";

export default function Footer() { 
    return (
        <footer className="footer" id="footer">
            <section className="footer_list">
                <div className="footer_produits">
                    <h2>Produits</h2>
                    <div className="footer_produits_texte">
                        <ul className="list_un">
                            <li><Link to="/carrelage" className="footer_produits_carrelage">Carrelage</Link></li>
                            <li><Link to="/parquet" className="footer_produits_parquet">Parquet</Link></li>
                            <li><Link to="/promo/carrelage-et-parquet" className="footer_produits_sanitaire">Promo</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/carrelage/sols-&-murs/pas-de-sous-categorie/stock-et-commande" className="footer_produits_sanitaire">Carrelage Sols & Murs</Link></li>
                            <li><Link to="/parquet/massif/pas-de-sous-categorie/stock-et-commande" className="footer_produits_sanitaire">Parquet Massif</Link></li>
                            <li><Link to="/carrelage/accessoires-carrelage/pas-de-sous-categorie/stock-et-commande" className="footer_produits_sanitaire">Accessoires Carrelage</Link></li>
                            <li><Link to="/parquet/accessoires-parquet/pas-de-sous-categorie/stock-et-commande" className="footer_produits_sanitaire">Accessoires Parquet</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer_services">
                    <h2>Services</h2>
                    <div className="footer_services_texte">
                        <ul>
                            <li><a href="https://maisondurevetement.odoo.com/contact" className="footer_produits_formulaire">Formulaire de contact</a></li>
                            <li><Link to="/espace-client" className="footer_produits_espace">Espace Client</Link></li>
                            <li><span onClick={productsService.getOrderId} aria-label="Mon Panier" title="Accéder à votre panier">Mon Panier</span></li>
                            <li><Link to="/livraison-et-retour" className="footer_produits_livraison">Livraison & Retour</Link></li>
                        </ul>
                        <ul className="rs">
                            <li><a aria-label="Compte Instagram Maison du Revêtement" href="https://www.instagram.com/maison_du_revetement/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a aria-label="Compte Facebook Maison du Revêtement" href="http://www.facebook.com/profile.php?id=100089922982015" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></a></li>
                        </ul>   
                    </div>
                </div>
                <div className="footer_contact">
                    <div className="footer_contact_adresse">
                        <h2>Paris 17ème</h2>
                        <ul>
                            <li className="footer_contact_telephone"><p>Téléphone :</p><a href="tel:+33182830071">+331.82.83.00.71</a></li>
                            <li className="footer_contact_email"><p>Email :</p><a className="email" href="mailto:paris17@maisondurevetement.com">paris17@maisondurevetement.com</a></li>
                            <li className="footer_contact_showroom"><p>Adresse du Showroom :</p><a href="https://www.google.com/maps/place/Maison+du+Rev%C3%AAtement+%7C+Paris+17/@48.8819998,2.2938824,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66f131a15cf87:0x7ea70892d2e6ac07!8m2!3d48.8819963!4d2.2964573!16s%2Fg%2F11kqtpd7qp?entry=ttu">58 Rue Pierre Demours<br />75017 Paris</a></li>
                        </ul>
                    </div>
                    <div className="footer_contact_adresse">
                        <h2>Entrepôt Porte de Paris</h2>
                        <ul>
                            <li className="footer_contact_telephone"><p>Téléphone :</p><a href="tel:+33156555526">+331.56.55.55.26</a></li>
                            <li className="footer_contact_email"><p>Email :</p><a className="email" href="mailto:support@maisondurevetement.com">support@maisondurevetement.com</a></li>
                            <li className="footer_contact_showroom"><p>Adresse du Showroom :</p><a href="https://www.google.com/maps/place/Maison+du+Rev%C3%AAtement,+23+Av.+du+Chemin+des+Reniers,+92390+Villeneuve-la-Garenne/@48.9326753,2.3242003,17z/data=!4m6!3m5!1s0x47e66fe73242f781:0xfce1191e0496d851!8m2!3d48.9326753!4d2.3242003!16s%2Fg%2F11s7qzn61f">23 Avenue du Chemin des Reniers<br />92390 Villeneuve-la-Garenne</a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="footer_end">
                <div className="footer_end_texte">
                    <p>© Maison du Revêtement</p>
                </div>
                <div className="footer_end_texte">
                    <Link to="/conditions-generales-de-vente">CGVU</Link>
                </div>
                <div className="footer_end_logo">
                    <a href="/">
                        <img src={PetitLogo} alt="Petit Logo Maison du Revêtement"/>
                    </a>
                </div>
                <div className="footer_end_texte">
                    <Link to="/mentions-legales">Mentions légales</Link>
                </div>
                <div className="footer_end_texte">
                    <p>2023 - 2024</p>
                </div>
            </section>
        </footer>
    )
}