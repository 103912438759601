/* Code */
export default function Aide(props) {
    let catName = props.catName;

    /* On utilise un switch/case pour déterminer quels textes afficher selon la gamme sélectionnée */
    function RenderSwitch(catName) {
        switch(catName) {
            case 'Carrelage':
                return (
                <section className="aide">
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Le Carrelage Sols & Murs
                        </div>
                        <div className="aide_paragraphe_texte">
                            Réputé pour son entretien minimal, le carrelage saura résister à toutes les épreuves du quotidien. Fort d'un choix important d'aspects, de formes et de couleurs, le carrelage se démarque par sa polyvalence et sa propension à reflèter la lumière.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Les Faïences
                        </div>
                        <div className="aide_paragraphe_texte">
                            Carreaux de revêtement en céramique émaillée utilisés principalement pour les murs intérieurs, ce type de revêtement est fabriqué à partir d'argile fine qui est ensuite recouverte d'un émail coloré et vitrifié.
                            Elles offrent une finition lisse et brillante, une variété de couleurs et de motifs, ainsi qu'une résistance à l'eau et à l'usure. Elles sont couramment utilisées dans les cuisines, les salles de bains et d'autres espaces intérieurs où une surface décorative et facile à nettoyer est recherchée.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Les Extérieurs
                        </div>
                        <div className="aide_paragraphe_texte">
                            Parfait pour vos terrasses, patios, jardins, piscines ou allées, notre sélection de revêtements de sol a été spécialement conçue pour une utilisation en extérieur grâce à leur propriété antidérapante et de résistance aux intempéries.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Les Pierres Naturelles
                        </div>
                        <div className="aide_paragraphe_texte">
                            Le carrelage en pierres naturelles est fabriqué à partir de différentes pierres extraites directement de la nature. 
                            Ces pierres naturelles comprennent des matériaux tels que le marbre, le granit, le travertin, le calcaire, l'ardoise et d'autres types de roches. 
                            Leur utilisation dans le carrelage offre une esthétique naturelle et unique, ainsi qu'une grande durabilité.
                            Chaque carreau de pierre présente des variations de couleur, de veines et de motifs, ce qui donne un aspect visuellement attrayant et organique. Ces variations ajoutent du caractère et de la profondeur aux espaces intérieurs et extérieurs.
                        </div>
                    </div>
                    <span className="aide_ligne"></span>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Notre équipe vous accompagne
                        </div>
                        <div className="aide_paragraphe_texte">
                            Chez Maison du Revêtement, nous savons que l'acquisition d'un parquet ou d'un carrelage est bien plus qu'un simple choix. 
                            Il s'inscrit souvent dans un projet de vie à long terme et nécessite une attention particulière.
                            C'est pour cela que notre équipe est là pour comprendre vos besoins afin de répondre au mieux à toutes vos questions.
                            Téléphone, email, showroom, c'est à vous de choisir par quel moyen nous solliciter !
                        </div>
                    </div>
                </section>);
            case 'Parquet':
                return (
                <section className="aide">
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Le Parquet Massif
                        </div>
                        <div className="aide_paragraphe_texte">
                            Les Massifs sont composés de véritables planches de bois d'une seule essence, sans aucun ajout de couches ou de matériaux synthétiques. 
                            De structure naturellement solide et durable, le parquet peut être poncé et rénové plusieurs fois au cours de sa durée de vie, permettant ainsi de restaurer son aspect d'origine.
                            Apprécié pour son esthétique chaleureuse et naturelle, ainsi que pour ses variations de couleur et de grain qui ajoutent du caractère à une pièce, il offre également une excellente isolation thermique et acoustique, contribuant ainsi à créer une atmosphère confortable dans l'espace où il est installé.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Le Parquet Contrecollé
                        </div>
                        <div className="aide_paragraphe_texte">
                            Composé de plusieurs couches de matériaux, le parquet contrecollé est conçu pour offrir à la fois la beauté du bois naturel, une stabilité accrue et est moins sujet aux variations dimensionnelles causées par l'humidité et la chaleur par rapport au parquet massif.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Le Parquet Stratifié
                        </div>
                        <div className="aide_paragraphe_texte">
                            Le parquet stratifié est un revêtement de sol fabriqué à partir d'un panneau de fibres à haute densité avec une couche décorative imitant le grain du bois, généralement avec une impression photographique de haute qualité. 
                            Il offre une apparence réaliste du bois, une durabilité élevée et un entretien facile.
                        </div>
                    </div>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Le Parquet PVC
                        </div>
                        <div className="aide_paragraphe_texte">
                            Conçu pour imiter l'apparence du bois naturel, le parquet PVC également connu sous le nom de sol vinyle en imitation parquet, est fabriqué à partir de polyvinyle chlorure.
                            Il est composé de plusieurs couches, offrant une résistance à l'usure, un entretien facile et une grande variété de styles. Il est apprécié pour sa durabilité et sa facilité d'installation.
                        </div>
                    </div>
                    <span className="aide_ligne"></span>
                    <div className="aide_paragraphe">
                        <div className="aide_paragraphe_titre">
                            Notre équipe vous accompagne
                        </div>
                        <div className="aide_paragraphe_texte">
                            Chez Maison du Revêtement, nous savons que l'acquisition d'un parquet ou d'un carrelage est bien plus qu'un simple choix. 
                            Il s'inscrit souvent dans un projet de vie à long terme et nécessite une attention particulière.
                            C'est pour cela que notre équipe est là pour comprendre vos besoins afin de répondre au mieux à toutes vos questions.
                            Téléphone, email, showroom, c'est à vous de choisir par quel moyen nous solliciter !
                        </div>
                    </div>
                </section>);
            default:
                return null;
        }
    }

    return (
        <>{ RenderSwitch(catName) }</>
    )
}