/* Code */
export default function TexteGammes({className, titre1, titre2, texte1, texte2, texte3, texte4, texte5, texte6}) {
    return (
        <section className={`gammes_contenu_${className}`}>
            <h2 className="gammes_contenu_titre">{titre1}<br />{titre2}</h2>
            <div className="gammes_contenu_texte">
                <p>{texte1}</p>
                <p>{texte2}</p>
                <p>{texte3}</p>
                <p>{texte4}</p>
                <p>{texte5}</p>
                <p>{texte6}</p></div>
        </section>
    )
}