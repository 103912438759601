/* Code */
export default function Plus() {
    return (
        <section className="plus">
            <h2 className="plus_titre">NOS ATOUTS</h2>
            <div className="plus_ensemble">
                <div className="plus_bloc">
                    <i className="fa-solid fa-dolly fa-xl"></i>       
                    <h3>RETRAIT GRATUIT EN ENTREPÔT</h3>
                </div>
                <div className="plus_bloc">
                    <i className="fa-solid fa-truck-fast fa-xl"></i>
                    <h3>LIVRAISON PARTOUT EN FRANCE</h3>
                </div>
                <div className="plus_bloc">
                    <i className="fa-solid fa-shop fa-xl"></i>
                    <h3>2 SHOWROOMS POUR VOS YEUX</h3>
                </div>
                <div className="plus_bloc">
                    <i className="fa-solid fa-mobile fa-xl"></i>
                    <h3>COMMANDE PAR TÉLÉPHONE</h3>
                </div>
                <div className="plus_bloc">
                    <i className="fa-solid fa-book fa-xl"></i>
                    <h3>+100 RÉFÉRENCES EN STOCK</h3>
                </div>
            </div>
        </section>
    )
}