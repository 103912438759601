/* Modules */
import { useEffect } from "react"

/* Components */
import Header from '../../composants/Header'
import Footer from '../../composants/Footer'

/* Code */
export default function Livraison() {
    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = "Livraison & Retour | Maison du Revêtement";
        document.getElementsByTagName("meta")[2].content=`Retrouver toutes les informations relatives à la livraison, les coûts associés ainsi que les conditions d'annulation de commande et de retour de marchandises.`;
    }, []);

    return (
        <>
            <header className="header header_livraison">
                <Header />
            </header>
            <main className="livraison">
                <div className="livraison_conteneur">
                    <div className="livraison_titre">
                        <h1>Livraison & Retrait</h1>
                    </div>
                    <span className="livraison_ligne"></span>
                    <div className="livraison_texte">
                        <div className="livraison_texte_livraison">
                            <p>Une fois confirmée, votre commande pourra être retirée à notre entrepôt ou bien directement livrée par nos soins à l'adresse de votre choix.
                            </p>
                            <p>Le délai d'expédition peut varier en fonction de la disponibilité des articles à savoir :<br />
                            - <strong>24 à 48 heures ouvrées</strong> pour les produits <strong>en stock</strong> ;<br />
                            - <strong>2 à 3 semaines</strong> pour les <strong>carrelages</strong> à la commande.<br />
                            - <strong>4 à 5 semaines</strong> pour les <strong>parquets</strong> à la commande.<br />
                            </p>
                            <p>Dès l'arrivage de vos produits en dépôt, nous vous contacterons par email pour définir ensemble une date et heure de livraison qui vous correspond.
                            </p>
                            <p>Nous conservons gratuitement votre commande jusqu'à 3 mois maximum.
                            </p>
                            <p>Dans le cadre d'un retrait gratuit, votre commande sera à récupérer à notre entrepôt situé au<br />
                            <strong>23 Avenue du Chemin des Reniers, 92390 Villeneuve-la-Garenne</strong>.
                            </p>
                        </div>
                        <div className="livraison_texte_retrait">
                            <div className="maps">
                                <div className="maps_canvas">
                                    <iframe className="maps_img" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10484.367381020078!2d2.3164755017245766!3d48.93269243351671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fe73242f781%3A0xfce1191e0496d851!2sMaison%20du%20Rev%C3%AAtement!5e0!3m2!1sfr!2sfr!4v1683196309616!5m2!1sfr!2sfr" title="Localisation sur Google Maps de Maison du Revêtement" height="400px" width="75%" loading="lazy" frameBorder="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="livraison_conteneur">
                    <div className="livraison_titre">
                        <h2>Coûts de livraison</h2>
                    </div>
                    <span className="livraison_ligne"></span>
                    <p className="livraison_espace"> Maison du Revêtement propose un service de livraison en France Métropolitaine au meilleur tarif !<br />
                    Le tarif calculé prend en compte le volume des marchandises.
                    </p>
                    <p>Voici quelques exemples de tarifs afin de vous faire une idée précise :
                    </p><br />
                    <h3>France Métropolitaine</h3><br />
                    <div className="livraison_couts">
                        <p>Jusqu'à 40m² :<br />
                            <strong>79,00 €HT</strong>
                        </p>
                        <p>60m² :<br />
                            <strong>249,00 €HT</strong>
                        </p>
                        <p>100m² :<br />
                            <strong>349,00 €HT</strong>
                        </p>
                    </div><br />
                    <p>La livraison est offerte pour toute commande de <strong>3 500 €HT</strong> d'achat !</p><br />
                    <p>Pour toute demande d'informations, n'hésitez pas à nous contacter en amont par téléphone au 01.82.83.00.71 ou par email à l'adresse suivante : paris17@maisondurevetement.com.</p>
                </div>
                <div className="livraison_conteneur">
                    <div className="livraison_titre">
                        <h2>Annulation de commande & Retour de marchandises</h2>
                    </div>
                    <span className="livraison_ligne"></span>
                    <div className="livraison_texte">
                        <p>Annulation de commande :<br />
                        Une fois la commande passée sur notre site, vous avez la possibilité d’annuler votre commande pendant les SEPT (7) JOURS suivants.
                        Cette annulation doit faire l’objet d’une demande formulée via notre formulaire de contact ou directement par email à l’adresse suivante : support@maisondurevetement.com. Veuillez bien indiquer votre numéro de commande afin de pouvoir vous identifier facilement.
                        Toutefois, si une annulation nous parvenait alors que l'expédition ou la livraison est déjà effectuée, l'annulation ne serait pas prise en compte, la date d'enlèvement par le transporteur faisant foi.
                        Dans ce cas, le droit de rétractation peut éventuellement prendre le relai.<br /><br />
                        Droit de rétractation :<br />
                        Le droit de rétractation prend effet à la livraison de la commande et cela pour une durée de QUATORZE (14) JOURS.
                        Ce droit ne peut s’appliquer si les produits ou consommables livrés ont fait l’objet d’un usage même limité.
                        Après étude par nos services, si le droit de rétractation est applicable et uniquement si les produits sont dans un état d’origine permettant la revente, le client est chargé d’effectuer le retour à notre entrepôt par ses propres moyens.
                        Le retour des produits donnera alors lieu au remboursement des produits et non de la commande, c’est-à-dire en excluant les éventuels coûts de livraison et de retour.<br />
                        Vous pouvez consulter les conditions générales de vente disponibles sur le site pour tout complément d’informations.
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}