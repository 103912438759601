/* Module */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

/* Code */
export default function BoutonScrollToTop() {
    /* Permet de remonter en haut de page */
    function handleScrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div className="boutonScrollToTop">
            <button onClick={handleScrollToTop} aria-label="Bouton de remontée en haut de page"><FontAwesomeIcon icon={faChevronUp} size="2xl" style={{color: "#28292A"}} /></button>
        </div>
    )
}